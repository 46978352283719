//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/NavigationRouteWrapper/helpers";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number;
  productDetails:any;
  checkIn: Date | null;
  checkOut: Date | null;
  guests: string;
  horses: string;
  currentOpen: string;
  loading:boolean;
  isShowAllImages:boolean;
  isExpanded:boolean;
  isDrawerOpen:boolean;
  showLoginPopup:boolean;
  reportConfig:any;
  showReportPopup:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productDetailsCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      loading:false,
      isShowAllImages:false,
      guests: '0',
      horses: '0',
      isExpanded:false,
      isDrawerOpen:false,
      showLoginPopup:false,
      productDetails:null,
      reportConfig:{},
      showReportPopup:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.productDetailsCallId && responseJson) {
        this.handleProductDetailsResponse(responseJson);
      }
  }

    // Customizable Area End
  }

  // Customizable Area Start
  getProductDetails = async (id:any) => {
    if(!id){
      return;
    }
    this.setState({isloading:true})
    this.productDetailsCallId = await apiCall({
      method: "GET",
      endPoint: `bx_block_bulk_uploading/listings/${id}/show_listing`,
    });
    this.checkDataFromStorage();
  }

  checkDataFromStorage = () => {
    const checkIn = localStorage.getItem('checkIn');
    const checkOut = localStorage.getItem('checkOut');
    const guests = localStorage.getItem('guests');
    const horses = localStorage.getItem('horses');
    this.setState({ checkIn: checkIn ? new Date(checkIn) : null, checkOut: checkOut ? new Date(checkOut) : null, guests: guests || '0', horses: horses || '0' });
  }

  handleProductDetailsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ productDetails: responseJson.data, reportConfig: { type: "report_listing_review", reportableId: responseJson.data.id } });
    }
    this.setState({ isloading: false });
  }

  toggleReport = () => this.setState({ showReportPopup: !this.state.showReportPopup })

  getDisc = () => {
    let string = '';
    const { attributes } = this.state.productDetails;
    if (attributes.apartment.length) {
      string += ' ' + 'Apartment'
    } if (attributes.campsite.length) {
      string += ' . ' + 'Campsite'
    } if (attributes.hookups.length) {
      string += ' . ' + 'Hookups'
    }
    return string;
  }

  onChangeValue = (currentOpen: string) => {
    this.setState({ currentOpen });
  }

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
  }

  showAllImages = () => {
    this.setState({ isShowAllImages: true })
  }

  convertFormat=(dateStr)=>{
    const parts = dateStr.split('-');
    const day = parseInt(parts[0], 10);   
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  getBlackoutDates = () => {
    const blackouts = this.state.productDetails.attributes?.blackout_dates.map((item) => this.convertFormat(item));
    return blackouts;
  }

  onChangeStr = () => this.state.currentOpen === 'checkout' ? '' : 'checkout'

  isCheckInOpen = () => this.state.currentOpen === 'checkIn';

  getCheckoutVal = (date) => this.state.checkOut && date && date > this.state.checkOut ? null : this.state.checkOut;

  checkInTime = () => this.state.productDetails.attributes?.check_in_time;
  checkOutTime = () => this.state.productDetails.attributes?.check_out_time;

  getMinDate = (date) => new Date(date) < new Date() ? new Date() : new Date(date)

  isDisabled = () => {
    const result = this.state.checkIn && this.state.checkOut && this.state.guests != '0';
    return !result
  }

  onBackPress = () => {
    if (this.state.isShowAllImages) {
      this.setState({ isShowAllImages: false })
    } else {
      this.props.navigation.goBack?.();
    }
  }

  toggleExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  toggleLoginPopup = () => {
    let token = localStorage.getItem("token");
    let name = localStorage.getItem("name");
    if (token && name) {
      const productId = this.state.productDetails.id;
      localStorage.setItem("isBookingReqChange", 'NO');
      this.props.navigation.navigate("GuestBookingForm", { path: { productId } });
    } else {
      this.setState({ showLoginPopup: !this.state.showLoginPopup });
    }
  }

  onLogin = () => {
    this.toggleLoginPopup();
    this.props.navigation.navigate('EmailAccountLoginBlock');
  }

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };
  // Customizable Area End
}
