// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IOption {
  label: string;
  status: string;
  required?: boolean;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  isAmenityValid: boolean;
  option: IOption[];
  currentOption: number;
  amenitiesList: any[];
  isAmenitiesSaveClicked: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class CreateListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSettingApiCallId: string = "";
  getAmenitiesListApiCallId: string = "";
  updateAmenitiesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      option: OPTIONS,
      isAmenityValid: false,
      currentOption: -1,
      amenitiesList: [],
      isAmenitiesSaveClicked: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAmenitiesListApiCallId) {
        if (responseJson.data) {
          const amenitiesList = responseJson.data.map((item: any) => {
            return {
              price: null,
              selected: null,
              horse_capacity: "0",
              price_per_horse: null,
              type: item.attributes.amenity_name,
              icon: item.attributes.amenity_icon?.url
            }
          });
          this.setState({ amenitiesList });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.updateAmenitiesApiCallId) {
        if (responseJson.data) {
          toast.success("Amenities data save successfully!!");
        } else if (responseJson.error) {
          toast.error("Something went wrong!");
        }
      }
      this.setState({ isLoading: false });
    }
  }

  handleAmenityChange = (index: number, key: string, value: string) => {
    this.setState((prevState) => {
      const amenitiesList = [...prevState.amenitiesList];
      const amenity = { ...amenitiesList[index], [key]: value };
      switch (key) {
        case "selected":
          if (amenity.horse_capacity === "0") amenity.horseErr = "Please select horses";
          if (value === "free") amenity.price = "";
          else if (value === "set_price" && !amenity.price) amenity.priceErr = "Please add price";
          amenity.radioErr = "";
          break;
        case "price":
          if (!value.trim()) {
            amenity.priceErr = "Please add price";
          } else if (Number(value) === 0) {
            amenity.priceErr = "Price can't be 0";
          } else {
            amenity.priceErr = "";
          }
          break;
        case "horse_capacity":
          if (!value) {
            amenity.horseErr = "Please select horses";
          } else {
            amenity.horseErr = "";
          }
          if (!amenity.selected) {
            amenity.radioErr = "Please select option";
          }
          break;
      }
      amenitiesList[index] = amenity;
      const isAmenityValid = amenitiesList.every((item) => !item.radioErr && !item.horseErr && !item.priceErr);
      return { amenitiesList, isAmenityValid };
    });
  }

  onChangeOption = (currentOption: number) => {
    if (this.state.currentOption === currentOption) return;
    this.setState({ currentOption, isAmenityValid: false });
    if (currentOption === 3) {
      this.getAmenitiesList();
    }
  }

  updateAmenities = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    }
    const amenities = this.state.amenitiesList
      .filter((item) => item.horse_capacity !== "0" && item.selected)
      .map((item) => {
        return {
          "type": item.type,
          "price": item.price,
          "horse_capacity": item.horse_capacity,
          "price_per_horse": item.price ? true : false
        }
      });
    if (amenities.length === 0) {
      toast.warn("Add at least one amenity!");
      return;
    }

    this.setState({ isLoading: true });
    const body = {
      "data": {
        "amenities": amenities
      }
    }
    this.updateAmenitiesApiCallId = await apiCall({
      header,
      body,
      method: "PUT",
      endPoint: `bx_block_bulk_uploading/listings/145/add_amenities`,
    });
  }

  getAmenitiesList = async () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getAmenitiesListApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_bulk_uploading/amenities`,
    });
  }

  // Customizable Area End
}

// Customizable Area Start
const OPTIONS = [
  { label: "General Information", status: "Pending", required: true },
  { label: "Horse Stabling", status: "Pending" },
  { label: "Guest accommodation", status: "Pending" },
  { label: "Amenities", status: "Pending" },
  { label: "Availability", status: "Pending", required: true },
  { label: "Payment details", status: "Pending", required: true },
  { label: "KYC Verification", status: "Pending", required: true },
];
// Customizable Area End