import React from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Typography, Divider, ClickAwayListener } from "@mui/material";
import moment from "moment";
import ReactSelect from "react-select";
import { styled } from '@mui/material/styles';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import CommonDatePicker from "./helper/CommonDatePicker.web";

interface Props {
  onChangeOpenValue?: (location: string) => void;
  onSearch?: (location: string, checkIn: Date | null, checkOut: Date | null, guests: string, horses: string) => void;
}

interface S {
  location: string;
  checkIn: Date | null;
  checkOut: Date | null;
  guests: string;
  horses: string;
  currentOpen: string;
}

interface SS {
  id: string;
}

export default class Filter extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentOpen: "",
      guests: localStorage.getItem("guests") ?? "",
      horses: localStorage.getItem("horses") ?? "",
      location: localStorage.getItem("location") ?? "",
      checkIn: localStorage.getItem("checkIn") ? new Date(localStorage.getItem("checkIn") ?? "") : null,
      checkOut: localStorage.getItem("checkOut") ? new Date(localStorage.getItem("checkOut") ?? "") : null,
    }
  }

  onSearch = () => {
    if (this.state.checkIn && !this.state.checkOut) {
      toast.warning("Please select check out date!");
      return;
    }
    if (this.state.checkOut && !this.state.checkIn) {
      toast.warning("Please select check in date!");
      return;
    }
    this.onChangeValue("");
    this.props.onSearch && this.props.onSearch(this.state.location, this.state.checkIn, this.state.checkOut, this.state.guests, this.state.horses);
  }

  onChangeValue = (currentOpen: string) => {
    this.setState({ currentOpen });
    this.props.onChangeOpenValue && this.props.onChangeOpenValue(currentOpen);
  }

  getWhoInfo = () => {
    const { guests, horses } = this.state;
    const guestText = guests ? `${guests} Guest${parseInt(guests) > 1 ? 's' : ''}` : '';
    const horseText = horses ? `${guests ? '• ' : ''}${horses} Horse${parseInt(horses) > 1 ? 's' : ''}` : '';
    return `${guestText} ${horseText}`.trim();
  };

  render() {
    return (
      <Wrapper>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => this.onChangeValue("")}
        >
          <Box className="search-wrapper">
            <Box
              className="section left"
              sx={{ backgroundColor: `${this.state.currentOpen === "location" ? "#F1F5F9" : "#fff"}` }}
              onClick={() => this.onChangeValue("location")}
            >
              <Typography className="title">
                Location
              </Typography>
              <GooglePlacesAutocomplete
                apiKey="apikey"
                debounce={500}
                data-testId='location'
                selectProps={{
                  isClearable: true,
                  classNamePrefix: "location",
                  placeholder: "Search destinations",
                  noOptionsMessage: () => null,
                  onMenuClose: () => {
                    this.onChangeValue("");
                  },
                  components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
                  value: this.state.location ? [{ label: this.state.location, value: this.state.location }] : undefined,
                  onInputChange: () => {
                    this.onChangeValue("location");
                  },
                  onChange: (event: any) => {
                    const location = event?.value?.description ?? "";
                    this.setState({ location });
                    localStorage.setItem("location", location);
                  }
                }}
              />
            </Box>
            <Divider className="divider" orientation="vertical" flexItem />
            <Box className="section right">
              <Box
                className="child"
                sx={{ backgroundColor: `${this.state.currentOpen === "checkIn" ? "#F1F5F9" : "#fff"}` }}
                onClick={() => this.onChangeValue(this.state.currentOpen === "checkIn" ? "" : "checkIn")}
              >
                <Typography className="title">
                  Check in
                </Typography>
                <Typography className="sub-title">
                  {this.state.checkIn ? moment(this.state.checkIn).format('MMM DD/YY') : <Box component="span" sx={{ color: "hsl(0, 0%, 50%)" }}>Add dates</Box>}
                </Typography>
              </Box>
              {this.state.currentOpen === "checkIn" &&
                <Box className="date-wrapper">
                  <CommonDatePicker
                    inline
                    disabledKeyboardNavigation
                    minDate={new Date()}
                    selected={this.state.checkIn}
                    onChange={(date) => {
                      const checkIn = date;
                      const checkOut = this.state.checkOut && date && date > this.state.checkOut ? null : this.state.checkOut;
                      this.setState({ checkIn, checkOut });
                      localStorage.setItem("checkIn", checkIn.toString());
                      localStorage.setItem("checkOut", checkOut?.toString() ?? "");
                      this.onChangeValue("");
                    }}
                  />
                </Box>
              }
            </Box>
            <Divider className="divider" orientation="vertical" flexItem />
            <Box className="section right">
              <Box
                className="child"
                sx={{ backgroundColor: `${this.state.currentOpen === "checkout" ? "#F1F5F9" : "#fff"}` }}
                onClick={() => this.onChangeValue(this.state.currentOpen === "checkout" ? "" : "checkout")}
              >
                <Typography className="title">
                  Check out
                </Typography>
                <Typography className="sub-title">
                  {this.state.checkOut ? moment(this.state.checkOut).format('MMM DD/YY') : <Box component="span" sx={{ color: "hsl(0, 0%, 50%)" }}>Add dates</Box>}
                </Typography>
              </Box>
              {this.state.currentOpen === "checkout" &&
                <Box className="date-wrapper">
                  <CommonDatePicker
                    inline
                    selectsRange
                    selectsEnd
                    selected={this.state.checkOut}
                    disabledKeyboardNavigation
                    minDate={this.state.checkIn ?? new Date()}
                    startDate={this.state.checkIn}
                    endDate={this.state.checkOut}
                    onChange={(date: [Date | null, Date | null]) => {
                      const [startDate, endDate] = date;
                      if (!endDate) {
                        this.setState({ checkOut: startDate });
                        localStorage.setItem("checkOut", startDate?.toString() ?? "");
                      } else {
                        this.setState({ checkIn: startDate, checkOut: endDate });
                        localStorage.setItem("checkIn", startDate?.toString() ?? "");
                        localStorage.setItem("checkOut", endDate?.toString() ?? "");
                      }
                      this.onChangeValue("");
                    }}
                  />
                </Box>
              }
            </Box>
            <Divider className="divider" orientation="vertical" flexItem />
            <Box className="section right">
              <Box
                className="child"
                sx={{ backgroundColor: `${this.state.currentOpen === "who" ? "#F1F5F9" : "#fff"}` }}
                onClick={() => this.onChangeValue(this.state.currentOpen === "who" ? "" : "who")}
              >
                <Typography className="title">
                  Who
                </Typography>
                <Typography className="sub-title">
                  {!this.state.guests && !this.state.horses
                    ? <Box component="span" sx={{ color: "hsl(0, 0%, 50%)" }}>Add guests</Box>
                    : <>{this.getWhoInfo()}</>
                  }
                </Typography>
              </Box>
              {this.state.currentOpen === "who" &&
                <Box className="who-wrapper">
                  <Box className="who-title">
                    <Typography className="title">
                      Who's coming with you?
                    </Typography>
                    <Box mb="30px" mt="30px" display="flex" justifyContent="space-between">
                      <Typography className="option-title">
                        Guests
                      </Typography>
                      <ReactSelect
                        isSearchable={false}
                        classNamePrefix="dropdown"
                        value={{ label: this.state.guests, value: this.state.guests }}
                        options={["1", "2", "3", "4", "5", "6", "7", "8", "9"].map((val) => ({ label: val, value: val }))}
                        components={{ IndicatorSeparator: () => null }}
                        onChange={(e: any) => {
                          this.setState({ guests: e.value });
                          localStorage.setItem("guests", e.value);
                        }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="space-between" pb="25px">
                      <Typography className="option-title">
                        Horses
                      </Typography>
                      <ReactSelect
                        isSearchable={false}
                        classNamePrefix="dropdown"
                        value={{ label: this.state.horses, value: this.state.horses }}
                        options={["1", "2", "3", "4", "5", "6", "7", "8", "9"].map((val) => ({ label: val, value: val }))}
                        components={{ IndicatorSeparator: () => null }}
                        onChange={(e: any) => {
                          this.setState({ horses: e.value });
                          localStorage.setItem("horses", e.value);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              }
            </Box>
            <Box className="searchIcon" onClick={this.onSearch}>
              <SearchIcon />
            </Box>
          </Box>
        </ClickAwayListener>
      </Wrapper>
    )
  }
}

const Wrapper = styled(Box)(({ theme }) => ({
  fontFamily: "Spartan",
  "& .search-wrapper": {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    backgroundColor: "#fff",
    "& .section": {
      cursor: "pointer",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      "&.left": {
        zIndex: "9999",
        width: "200px",
        padding: "10px 5px 4px 12px",
        borderTopLeftRadius: "16px",
        borderBottomLeftRadius: "16px",
        "&:hover": {
          backgroundColor: "#F1F5F9"
        },
      },
      "& .date-wrapper": {
        zIndex: "9999",
        top: "65px",
        cursor: "default",
        position: "absolute",
        right: "0px",
      },
      "&.right": {
        width: "151px",
      },
      "& .child": {
        height: "100%",
        padding: "10px 5px 3px 10px",
        "&:hover": {
          backgroundColor: "#F1F5F9"
        },
      },
      "& .location__control": {
        border: "none",
        minHeight: "26px",
        borderRadius: "4px",
        boxShadow: "none",
        borderBottom: "none",
        "&:hover": {
          borderColor: "black",
          boxShadow: "none"
        }
      },
      "& .location__input-container": {
        fontFamily: "Spartan",
        fontWeight: "500",
        fontSize: "13px",
        color: "#003249"
      },
      "& .location__value-container": {
        padding: "0px"
      },
      "& .location__placeholder": {
        fontFamily: "Spartan",
        fontWeight: "500",
        fontSize: "13px"
      }
    },
    "& .title": {
      marginBottom: "5px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "13px"
    },
    "& .sub-title": {
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "13px",
      color: "#003249"
    },
    "& .divider": {
      border: "1px solid #E2E8F0",
      height: "35px",
      margin: "auto"
    },
    "& .who-wrapper": {
      overflow: "unset",
      position: "absolute",
      top: "65px",
      right: "0px",
      zIndex: "1300",
      borderRadius: "16px",
      border: "1px solid #E2E8F0",
      backgroundColor: "#fff",
      "& .who-title": {
        padding: "24px",
        minWidth: "280px",
        cursor: "default",
        "& .title": {
          fontFamily: "Spartan",
          fontWeight: "700",
          fontSize: "16px",
          color: "#003249"
        }
      },
      "& .option-title": {
        fontFamily: "Spartan",
        fontWeight: "700",
        fontSize: "14px",
        color: "#003249"
      }
    },
    "& .searchIcon": {
      color: "#000",
      width: "50px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTopRightRadius: "16px !important",
      borderBottomRightRadius: "16px !important",
      "&:hover": {
        backgroundColor: "#F1F5F9"
      },
    },
    "& .dropdown__control": {
      width: "85px",
      borderRadius: "8px"
    },
    "& .dropdown__single-value": {
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "13px",
      color: "#003249"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px",
      "& .divider": {
        display: "none",
      },
    },
  },
}));