import React, { Component } from "react";
import DatePicker, { CalendarContainer, ReactDatePickerProps } from "react-datepicker";
import { Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "react-datepicker/dist/react-datepicker.css";

interface CommonDatePickerProps extends ReactDatePickerProps {
  selected?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  minDate?: Date;
  inline?: boolean;
  selectsEnd?: boolean;
  selectsRange?: any;
  disabledKeyboardNavigation?: boolean;
  onChange: (date: any, event?: any) => void;
}

interface CommonDatePickerState {
  selectedDate: Date | null;
}

export default class CommonDatePicker extends Component<CommonDatePickerProps, CommonDatePickerState> {
  constructor(props: CommonDatePickerProps) {
    super(props);
    this.state = {
      selectedDate: props.selected || null,
    };
  }

  handleChange = (date: Date | null, event?: React.SyntheticEvent<any, Event>) => {
    this.setState({ selectedDate: date });
    if (this.props.onChange) {
      this.props.onChange(date, event);
    }
  };

  myContainer = ({ children, ...props }: any) => (
    <Box color="#fff">
      <CalendarContainer {...props}>
        <Box className="date-heading">
          <Typography className="date-title">Dates</Typography>
          <InfoOutlinedIcon sx={{ color: "#94A3BB" }} />
        </Box>
        <Box display="flex" position="relative">{children}</Box>
      </CalendarContainer>
    </Box>
  );

  myContainer2 = ({ children, ...props }: any) => (
    <Box color="#fff">
      <CalendarContainer {...props}>
        <Box display="flex">
          <Box p="20px 24px 0px 23px" width="50%" display="flex" justifyContent="space-between">
            <Typography className="date-title">Dates</Typography>
            <InfoOutlinedIcon sx={{ color: "#94A3BB" }} />
          </Box>
          <Box p="20px 24px 0px 23px" width="50%" display="flex" justifyContent="space-between">
            <Typography className="date-title">Dates</Typography>
            <InfoOutlinedIcon sx={{ color: "#94A3BB" }} />
          </Box>
        </Box>
        <Box display="flex" position="relative">{children}</Box>
      </CalendarContainer>
    </Box>
  );

  render() {
    const { selected, monthsShown, selectsRange, startDate, endDate, onChange, ...rest } = this.props;
    return (
      <CustomDatePicker>
        <DatePicker
          {...rest}
          monthsShown={monthsShown}
          selectsRange={selectsRange}
          selected={selectsRange ? undefined : selected}
          startDate={selectsRange ? startDate : undefined}
          endDate={selectsRange ? endDate : undefined}
          calendarContainer={monthsShown ? this.myContainer2 : this.myContainer}
          onChange={this.handleChange}
        />
      </CustomDatePicker>
    )
  }
}

const CustomDatePicker = styled(Box)({
  "& .date-heading": {
    padding: "20px 24px 0px 23px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .date-title": {
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "16px",
    color: "#003249",
  },
  "& .react-datepicker__day--outside-month": {
    visibility: "hidden"
  },
  "& .react-datepicker": {
    borderRadius: "16px",
  },
  "& .react-datepicker__month-container": {
    padding: "7px 14px",
    borderRadius: "16px",
  },
  "& .react-datepicker__header": {
    backgroundColor: "#fff",
    borderBottom: "none",
    display: "flex",
    flexDirection: "column",
  },
  "& .react-datepicker__current-month": {
    order: "2",
    marginTop: "30px",
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "16px",
    color: "#003249",
  },
  "& .react-datepicker__navigation": {
    top: "63px"
  },
  "& .react-datepicker__day-names": {
    display: "flex",
    gap: "28px",
    marginLeft: "14px"
  },
  "& .react-datepicker__navigation--previous": {
    left: "15px"
  },
  "& .react-datepicker__navigation--next": {
    right: "15px"
  },
  "& .react-datepicker__navigation-icon::before": {
    borderColor: "#000",
    height: "7px",
    width: "7px"
  },
  "& .react-datepicker__day-name": {
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "14px",
    color: "#94A3B8",
    width: "9px",
    overflow: "hidden",
    '&:nth-of-type(2)': {
      width: "13px"
    },
    '&:nth-of-type(4)': {
      width: "17px"
    }
  },
  "& .react-datepicker__day--disabled": {
    color: "#94A3B8 !important",
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px",
    textDecoration: "line-through",
    textDecorationThickness: "1px",
  },
  "& .react-datepicker__day--in-selecting-range, & .react-datepicker__day--in-range": {
    borderRadius: "0px",
    margin: "0px",
    padding: "0.1666rem",
    backgroundColor: "#E2E8F0 !important",
    "&:hover": {
      padding: "0.1666rem",
    }
  },
  "& .react-datepicker__day--range-start.react-datepicker__day--range-end": {
    borderRadius: "8px !important",
  },
  "& .react-datepicker__day--selecting-range-end, & .react-datepicker__day--range-end": {
    color: "#fff !important",
    fontWeight: "500 !important",
    backgroundColor: "#003249 !important",
    borderRadius: "0px !important",
    borderTopRightRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
  },
  "& .react-datepicker__day--selecting-range-start, & .react-datepicker__day--range-start": {
    color: "#fff !important",
    fontWeight: "500 !important",
    backgroundColor: "#003249 !important",
    borderRadius: "0px !important",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important"
  },
  "& .react-datepicker__day": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
    width: "37px",
    margin: "0px",
    lineHeight: "2.2rem",
    padding: "0.1666rem",
  },
  "& .react-datepicker__day--selected": {
    color: "#fff !important",
    fontWeight: "500 !important",
    backgroundColor: "#003249 !important",
  },
  "& .react-datepicker__year-text--keyboard-selected, & .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#fff"
  }
});