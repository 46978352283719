//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Drawer,
  Avatar,
  Tooltip,
  CircularProgress,
  Dialog,DialogTitle, DialogContent, DialogActions,
  // Customizable Area End
  Grid,

} from "@mui/material";

// Customizable Area Start
import { createTheme, Theme, styled } from '@mui/material/styles';
import Header from '../../../components/src/AppHeader.web';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import GridOnIcon from '@mui/icons-material/GridOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ReactSelect from "react-select";
import moment from "moment";
import CommonDatePicker from '../../../../packages/components/src/helper/CommonDatePicker.web';
import TruncatedText from "../../../components/src/helper/TruncatedText.web";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportUser from "../../../blocks/blockedusers/src/ReportUser.web";
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const id = this.props.navigation.getParam?.('productId');
      this.getProductDetails(id);
  }

  componentDidUpdate(prevProps) {
    const id = this.props.navigation.getParam?.('productId');
    if (prevProps.navigation.getParam?.('productId') !== id) {
        this.getProductDetails(id);
    }
  }
  
  customStyles = {
    control: (provided) => ({
      ...provided,
      padding: '4px 2px',
      borderRadius: '8px'
    }),
    input: (provided) => ({
      ...provided,
    }),
  };

  renderInputView = () => {
    const { available_from, available_until } = this.state.productDetails.attributes
    return (
      <Box sx={{ marginTop: '40px' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
          className='section'
            sx={{
              backgroundColor: '#FFFFFF',
              width: '45%',
            }}
            
          >
            <Box sx={{ marginBottom: '16px' }}>
              <Typography sx={webStyle.inputHeader}>CHECK-IN</Typography>
            </Box>
            <Box
            data-test-id={'check-in-add'}
            onClick={(e) =>{
              this.onChangeValue(
                this.state.currentOpen === 'checkIn' ? '' : 'checkIn'
              )
            }}
              sx={{
                border: '1px solid #CBD5E1',
                borderRadius: '8px',
                padding: '10px 8px',
              }}
            >
              <Typography>
                {this.state.checkIn ? (
                  moment(this.state.checkIn).format('MMM DD/YY')
                ) : (
                  <Box component="span" sx={{ color: 'hsl(0, 0%, 50%)' }}>
                    Add dates
                  </Box>
                )}
              </Typography>
            </Box>
            {this.isCheckInOpen() && (
              <Box className="date-wrapper" sx={{position:'absolute',zIndex:3}}>
                <CommonDatePicker
                  inline
                  disabledKeyboardNavigation
                  minDate={new Date()}
                  maxDate={new Date(available_until)}
                  excludeDates={this.getBlackoutDates()}
                  data-test-id='check-in'
                  selected={this.state.checkIn}
                  onChange={(date) => {
                    const checkIn = date;
                    const checkOut = this.getCheckoutVal(date);
                    this.setState({ checkIn, checkOut });
                    localStorage.setItem("checkIn", checkIn.toString());
                    localStorage.setItem("checkOut", checkOut?.toString());
                    this.onChangeValue('');
                  }}
                />
              </Box>
            )}
          </Box>

          <Box
           className='section'
            sx={{
              backgroundColor: '#FFFFFF',
              minWidth: '45%',
            }}
          >
            <Box sx={{ marginBottom: '16px' }}>
              <Typography sx={webStyle.inputHeader}>CHECK-OUT</Typography>
            </Box>
            <Box
            data-test-id={'check-out-add'}
            onClick={() =>
              this.onChangeValue(this.onChangeStr())
            }
              sx={{
                border: '1px solid #CBD5E1',
                borderRadius: '8px',
                padding: '10px 8px',
              }}
            >
              <Typography>
                {this.state.checkOut ? (
                  moment(this.state.checkOut).format('MMM DD/YY')
                ) : (
                  <Box component="span" sx={{ color: 'hsl(0, 0%, 50%)' }}>
                    Add dates
                  </Box>
                )}
              </Typography>
            </Box>
            {this.state.currentOpen === 'checkout' && (
              <Box className="date-wrapper" sx={{position:'absolute',right:'0',zIndex:3}}>
                <CommonDatePicker
                  inline
                  selectsRange
                  selectsEnd
                  data-test-id='check-out'
                  selected={this.state.checkOut}
                  disabledKeyboardNavigation
                  excludeDates={this.getBlackoutDates()}
                  minDate={this.state.checkIn ?? this.getMinDate(available_from)}
                  maxDate={new Date(available_until)}
                  startDate={this.state.checkIn}
                  endDate={this.state.checkOut}
                  onChange={(date: [Date | null, Date | null]) => {
                    const [startDate, endDate] = date;
                      if (!endDate) {
                        this.setState({ checkOut: startDate });
                        localStorage.setItem("checkOut", startDate?.toString() ?? "");
                      } else {
                        this.setState({ checkIn: startDate, checkOut: endDate });
                        localStorage.setItem("checkIn", startDate?.toString() ?? "");
                        localStorage.setItem("checkOut", endDate?.toString() ?? "");
                      }
                    this.onChangeValue('');
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ marginTop: '24px' }}>
          <Typography sx={{ marginBottom: '16px', ...webStyle.inputHeader }}>Guests</Typography>
          <ReactSelect
            isSearchable={false}
            classNamePrefix="dropdown"
            data-test-id={'guest-select'}
            styles={this.customStyles}
            value={{ label: this.state.guests + ' Guests', value: this.state.guests }}
            options={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
              (val) => ({ label: val, value: val })
            )}
            components={{ IndicatorSeparator: () => null }}
            onChange={(e: any) => {
              this.setState({ guests: e.value });
              localStorage.setItem("guests", e.value);
            }}
          />
          <Box sx={{ marginTop: '24px' }}>
            <Typography sx={{ marginBottom: '16px', ...webStyle.inputHeader }}>Horses</Typography>
            <ReactSelect
              isSearchable={false}
              classNamePrefix="dropdown"
              data-test-id={'horses-select'}
              styles={this.customStyles}
              value={{ label: this.state.horses + ' Horses', value: this.state.horses }}
              options={['1', '2', '3', '4', '5', '6', '7', '8', '9'].map(
                (val) => ({ label: val, value: val })
              )}
              components={{ IndicatorSeparator: () => null }}
              onChange={(e: any) => {
                this.setState({ horses: e.value });
                localStorage.setItem("horses", e.value);
              }}
            />
          </Box>
        </Box>
        <BlackButton sx={{marginTop:'40px'}}  onClick={this.toggleLoginPopup} data-test-id={'book-now-btn'} disabled={this.isDisabled()}>Book Now</BlackButton>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={webStyle.note}>You won't be charged yet</Typography>
        </Box>
      </Box>
    );
  };

  renderLoginPopup = () => {
    return (
      <CustomDialog
        open={this.state.showLoginPopup}
        onClose={this.toggleLoginPopup}
      >
        <DialogTitle className="title">
        <MeetingRoomOutlinedIcon sx={{height:'48px', width:'48px',color:'003249'}}/>
          <IconButton
            onClick={this.toggleLoginPopup}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content">
          <Typography className="popup-title">Login to Enhance Your Experience!</Typography>
          <Typography className="popup-desc">Log in to unlock exclusive features and get the most out of haul inn. Your journey to a more sustainable world starts here!</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <BlackButton data-test-id={'login-btn'} onClick={this.onLogin}>Login</BlackButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderAllImages = () => {
    const data = this.state.productDetails.attributes.files;

    return (
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
          lg: 'repeat(4, 1fr)'
        },
        gap: '20px',
        margin: '20px 0', overflowX: 'scroll'
      }}>      {
          data.map((item, index) => {
            return <>
              <img src={item.file_url} style={{
                width: '100%',
                height: '274px',
                borderRadius: '8px',
              }} />
            </>
          })
        }
      </Box>
    )
  }

  renderImages = () => {
    const data = this.state.productDetails.attributes.files?.slice(0, 4)
    if(!data?.length){
      return null
    }
    return (
      <Box
        sx={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          margin: '20px 0',
          padding: '10px 0',
        }}
      >
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '10px',
          margin: '20px 0', minWidth: '900px',
        }}>
          {
            data.map((item, index) => {
              return <>
                {index < 3 ? <Box key={index} sx={{ marginRight: '20px' }}>
                  <img src={item.file_url} style={{
                    borderRadius: '8px',
                    width: '100%',
                    height: '160px',
                  }} />
                </Box> : <Box data-test-id={'show-all-images'} key={index} sx={{ marginRight: '20px', position: 'relative', cursor: 'pointer' }} onClick={this.showAllImages}>
                  <img src={item.file_url}
                    style={{
                      width: '100%',
                      height: '160px',
                      borderRadius: '8px',
                    }} />
                  <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 50, 73, 0.7)', borderRadius: '8px' }}></Box>

                  <Box sx={{
                    display: 'flex', position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginLeft: '15px',
                    transform: 'translate(-60%, -50%)',
                    zIndex: 9999, overlay: 'none',
                    flexDirection: 'row', alignContent: 'center'
                  }}>
                    <Box> <GridOnIcon sx={{ color: '#FFFFFF', height: '20px' }} /> </Box>
                    <Box sx={{ marginLeft: '8px' }}> <Typography
                      sx={{
                        fontFamily: 'Spartan',
                        fontWeight: 700,
                        fontSize: '16px',
                        color: 'white',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
                        zIndex: 1,
                        textAlign: 'center'
                      }}
                    >
                      Show all photos
                    </Typography></Box>
                  </Box>
                </Box>}
              </>
            })
          }
        </Box>
      </Box>
    )
  }

  renderDiscription = () => {
    const text = this.state.productDetails.attributes.description
    const isTextTruncated = text.length > 100;
    return (
      <Box>
        <Typography
          variant="body1"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: this.state.isExpanded ? 'none' : 2,
            WebkitBoxOrient: 'vertical',
            overflow: this.state.isExpanded ? 'visible' : 'hidden',
            textOverflow: 'ellipsis',
            ...webStyle.discText
          }}
        >
          {text}
        </Typography>
        {isTextTruncated && (
          <Typography
            variant="body2"
            onClick={this.toggleExpand}
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            {this.state.isExpanded ? 'See less' : 'See more'}
          </Typography>
        )}
      </Box>
    )
  }
  renderAmmenities = () => {
    const items = this.state.productDetails.attributes.amenities;
    return (
      <Box sx={webStyle.cardContainer}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}><Typography sx={webStyle.cardTitle}>AMENITIES</Typography>
          <MonetizationOnOutlinedIcon sx={{ height: '18px', paddingLeft: '10px', color: '#64748B' }} /><Typography sx={webStyle.titleInfo}>Additional cost</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              sx={{
                padding: '10px',
                backgroundColor: ' #F1F5F9',
                border: '1px solid #E2E8F0',
                borderRadius: '8px',
                textAlign: 'center',
              }}
            >
              <Typography sx={webStyle.ammenitiesText}>{item.type}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    )
  }
  renderAvailability = () => {
    const { available_from, available_until } = this.state.productDetails.attributes
    return (
      <Box sx={webStyle.cardContainer}>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '24px' }}> <Typography sx={{ ...webStyle.cardTitle, margin: '0px' }}>AVAILABILITY </Typography>{this.renderTooltip()}</Box>
        <Box className='section' sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <Box className="date-wrapper">
            <CommonDatePicker
              inline
              selectsRange
              maxDate={new Date(available_until)}
              monthsShown={2}
              selected={this.state.checkOut}
              excludeDates={this.getBlackoutDates()}
              disabledKeyboardNavigation
              minDate={this.state.checkIn ?? this.getMinDate(available_from)}
              startDate={this.state.checkIn}
              endDate={this.state.checkOut}
    
            />
          </Box>
        </Box>
      </Box>
    )
  }
  renderLocation = () => {
    const { coordinates } = this.state.productDetails.attributes
    const latitude = coordinates.latitude, longitude = coordinates.longitude;
    const additionalMarkers = [{ lat: 21.17024, lon: 72.831061 }];
    const markers = additionalMarkers
      .map((marker) => `${marker.lon},${marker.lat}`)
      .concat(`${longitude},${latitude}`)
      .join("~");
    const mapUrl = `https://static-maps.yandex.ru/1.x/?ll=${longitude},${latitude}&z=${6}&l=map&pt=${markers},pm2rdm&lang=en_US`;
    return (
      <Box sx={webStyle.cardContainer}>
        <Typography sx={webStyle.cardTitle}>WHERE YOU'LL BE</Typography>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}><PlaceOutlinedIcon sx={{ color: '#475569', height: '19px', marginRight: '8px' }} /><Typography sx={webStyle.locationText}>{this.state.productDetails.attributes.address}</Typography></Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}><Typography sx={webStyle.locationText}>{latitude + ', ' + longitude}</Typography><ContentCopyOutlinedIcon sx={{ color: '#475569', height: '19px', marginLeft: '8px' }} /></Box>
        </Box>
        <Box>
           <img width={'100%'}
            style={{ borderRadius: '16px',height:'550px' }}
            src={mapUrl} /> 
        </Box>
      </Box>
    )
  }
  renderReviews = () => {
    const { reviews, reviews_count } = this.state.productDetails.attributes;
    let data: any[] = reviews.data?.slice(0, 4);

    return (
      <Box sx={webStyle.cardContainer}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0px' }}><Box>
          <Typography sx={webStyle.cardTitle}>REVIEWS ({reviews_count})</Typography></Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' ,cursor:'pointer'}}><Typography onClick={this.toggleDrawer} sx={webStyle.seeAllText}>See All</Typography> <ChevronRightIcon /></Box></Box>
        <Box
          sx={{
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            padding: '10px 0',
          }}
        >
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '20px',
            minWidth: '900px',
          }}>
            {data?.map((item: any) => {
              return (this.renderReviewCard(item))
            })}
          </Box>
        </Box></Box>
    )
  }

  renderSendMessage = () => {
    return (<Box sx={{ ...webStyle.cardContainer, boxShadow: '0px 0px 18px 0px #00000014', padding: '20px' }} >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        {this.renderProfile()}
        <Box><Button sx={{ ...webStyle.sendButton, width: '325px' }}><Typography sx={webStyle.sendText}>Send a Message</Typography></Button></Box>
      </Box>
    </Box>)
  }

  renderReviewCard = (item: IReview, bgColor?: string) => {
    return (
      <ReviewCard bgcolor={bgColor}>
        <Box display="flex" alignItems="center" gap="10px">
          <Avatar className="card-img" src={item.attributes.reviewed_by.profile_photo?.url} />
          <Box>
            <Typography className="user-name">
              {item.attributes.reviewed_by.first_name + " " + item.attributes.reviewed_by.last_name}
            </Typography>
            <Typography className="date">{moment(item.attributes.created_date).format("MMMM D/YY")}</Typography>
          </Box>
        </Box>
        <Typography className="rating">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z" fill="#003249" />
          </svg>
          <span>{item.attributes.rating}</span>
        </Typography>
        <Typography className="review-text">
          {this.state.isOpen
            ? item.attributes.description
            : <TruncatedText text={item.attributes.description} maxLength={100} />
          }
        </Typography>
      </ReviewCard>
    );
  }

  renderDrawer = () => {
    const { reviews, reviews_count } = this.state.productDetails.attributes;
    return (
      <CustomDrawer
        anchor="right"
        data-test-id="drawer"
        open={this.state.isDrawerOpen}
        onClose={this.toggleDrawer}
      >
        <Box className="top">
          <Box mb="25px" display="flex" justifyContent="space-between">
            <Typography className="review">
              ALL REVIEWS ({reviews_count})
            </Typography>
            <CloseIcon className="close-icon" onClick={this.toggleDrawer} />
          </Box>
          <Box>
            <Grid container spacing={2}>
              {reviews.data?.map((item) => {
                return (
                  <Grid item xs={12}>
                    {this.renderReviewCard(item)}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </CustomDrawer>
    );
  }

  renderProfile = () => {
    const {host_account,average_rating_count}=this.state.productDetails.attributes;
    return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box>
        {host_account.profile_photo?.url ? <img
          src={host_account.profile_photo?.url || 'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250'}
          style={{
            width: '44px',
            height: '44px',
            borderRadius: '50%',
            objectFit: 'cover'
          }} /> :
          <AccountCircleIcon sx={{ height: '44px', width: '44px', color: '#64748B' }} />
        }
      </Box>
      <Box sx={{ marginLeft: '8px', width: '100%', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box><Typography sx={{ ...webStyle.profileText, marginRight: '30px' }}>{host_account.first_name +' '+ host_account.last_name}</Typography></Box>
          <Box sx={{}}><Typography sx={webStyle.profileText}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z" fill="#003249" />
            </svg>
            <span style={{ paddingLeft: '6px' ,transform:'none'}}>{average_rating_count.toFixed(1)}</span>
          </Typography></Box></Box>
        <Typography sx={webStyle.profileInfo}>1 listing • 10 Ratings</Typography></Box>
    </Box>
  }

  renderSendBtn = () => {
    return <Button sx={webStyle.sendButton}><Typography sx={webStyle.sendText}>Send a message</Typography></Button>
  }

  renderTooltip = () => {
    return (
      <Tooltip
        title="Availability might change depending on the number of horses and guests."
        placement="right"
        enterDelay={500}
        leaveDelay={200}
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              borderRadius: '8px',
              backgroundColor: '#334155',
              color: 'white',
              fontSize: '14px',
              lineHeight: '24px',
              fontWeight: 500,
            },
          },
        }}      >
        <InfoOutlinedIcon sx={{ marginLeft: '10px', color: '#64748B' }} />
      </Tooltip>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Header navigation={this.props.navigation}>
        <Wrapper>
          {this.state.isloading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: '#000' }} />
            </Box>
          ) : this.state.productDetails?.attributes && (
            <>
              <Box className="container">
              <Box sx={{display:'flex',flexDirection:'row'}}>  <Box className="left-box">
                  <Box className="heading">
                    <Box data-test-id={this.state.isShowAllImages ? 'back-press-images' :'back-press'} sx={{ height: '48px', width: '48px', cursor:'pointer' }} onClick={this.onBackPress}>
                      <ArrowBackIcon color={'#003249'} />
                    </Box>
                    <Box>
                      <Typography className="title">
                        {this.state.productDetails.attributes.title}
                      </Typography>
                        {!this.state.isShowAllImages ? <Typography className="disc">
                          {this.state.productDetails.attributes.address} |
                          {this.getDisc()} |
                          starting at $
                          {this.state.productDetails.attributes.starting_price}{' '}
                          USD / night
                        </Typography> : <Typography className="disc">
                          {this.state.productDetails.attributes.files?.length} photos for you
                        </Typography>}
                    </Box>
                  </Box>
                  { !this.state.isShowAllImages && <><Box sx={{ marginTop: '40px' }}>
                   {this.state.productDetails.attributes.cover_photo?.url && <img
                      src={
                        this.state.productDetails.attributes.cover_photo.url
                      }
                      placeholder="cover photo"
                      width="100%"
                      height='536px'
                      style={{ borderRadius: '15.93px' }}
                    />}
                  </Box>
                  {this.renderImages()}
                {this.renderDiscription()}
                {this.renderAmmenities()}
                {this.renderAvailability()}
                {this.renderLocation()}
                {this.renderReviews()}
                {this.renderSendMessage()}
                  </>}
                </Box>
                { !this.state.isShowAllImages && <Box className="right-box">
                  <Box
                    sx={{
                      display: 'flex',
                      height: '44px',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <OutlinedFlagIcon color={'#003249'} height={'24px'} />{' '}
                    <Typography
                    onClick={this.toggleReport}
                      sx={{
                        color: '#003249',
                        marginLeft: '8px',
                        marginRight: '16px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        fontSize: '16px',
                        cursor:'pointer'
                      }}
                    >
                      Report 
                    </Typography>
                  </Box>
                  <Box className="input-container">
                    <Box>
                      <Typography
                        sx={{
                          color: '#003249',
                          marginRight: '16px',
                          fontWeight: 700,
                          lineHeight: '28px',
                          fontSize: '20px',
                        }}
                      >
                        ${this.state.productDetails.attributes.starting_price}{' '}
                        USD / night
                      </Typography>{' '}
                    </Box>
                    {this.renderInputView()}
                  </Box>
                  <Box className="info-container">
                    <Box>
                      <Typography
                        sx={{
                          color: '#003249',
                          marginRight: '16px',
                          fontWeight: 700,
                          lineHeight: '28px',
                          fontSize: '20px',
                        }}
                      >
                        Important info
                      </Typography>
                    </Box>
                    <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AccessTimeIcon sx={webStyle.infoIcons}/></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>Check in time {this.checkInTime()}</Typography></Box></Box>
                    <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AccessTimeIcon sx={webStyle.infoIcons}/></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>Check out time {this.checkOutTime()}</Typography></Box></Box>
                    <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><AttachMoneyIcon sx={webStyle.infoIcons}/></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>10% Service Fee</Typography></Box></Box>
                    <Box sx={webStyle.infoItemContainer}><Box sx={webStyle.center}><CloseIcon sx={webStyle.infoIcons}/></Box><Box sx={webStyle.center}><Typography sx={webStyle.infoItem}>Cancelation & Refund Policy</Typography></Box></Box>
                  </Box>
                      <Box className="info-container">
                        {this.renderProfile()}
                        {this.renderSendBtn()}
                      </Box>
                </Box>}
              </Box>
              <Box> {this.state.isShowAllImages && this.renderAllImages()}
              </Box>
              </Box>
               {this.renderDrawer()} 
               {this.renderLoginPopup()}
               {<ReportUser isOpen={this.state.showReportPopup} reportConfig={this.state.reportConfig} onClose={this.toggleReport}/>}
            </>
          )}
        </Wrapper>
      </Header>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: '100%',
  '& .loading': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .container': {
    display: 'flex',
    flexDirection:'column',
    margin: '40px',
    '& .left-box': {
      width: '70%',
      marginRight: '20px',
      '& .heading': {
        display: 'flex',
        flexDirection: 'row',
        '& .title': {
          fontFamily: 'Spartan',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '26px',
          color: '#003249',
        },
        '& .disc': {
          fontFamily: 'Spartan',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '18px',
          color: ' #003249',
        },
      },
    },
    '& .right-box': {
      width: '30%',
      '& .input-container': {
        padding: '20px 40px',
        marginTop: '40px',
        borderRadius:'16px',
        boxShadow: '0px 0px 18px 0px #00000014',
      },
      '& .info-container': {
        padding: '20px 40px',
        borderRadius:'16px',
        marginTop: '20px',
        boxShadow: '0px 0px 18px 0px #00000014',
      },
    },
  },
  '& .search-wrapper': {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    border: '1px solid #E2E8F0',
    borderRadius: '16px',
    backgroundColor: '#fff',
    '& .section': {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      '&.left': {
        zIndex: '9999',
        width: '200px',
        padding: '10px 5px 4px 12px',
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        '&:hover': {
          backgroundColor: '#F1F5F9',
        },
      },
      '& .date-wrapper': {
        zIndex: '9999',
        top: '65px',
        cursor: 'default',
      },
      '&.right': {
        width: '151px',
      },
      '& .child': {
        height: '100%',
        padding: '10px 5px 3px 10px',
        '&:hover': {
        backgroundColor: '#F1F5F9',
        },
      },
      '& .location__control': {
        border: 'none',
        minHeight: '26px',
        borderRadius: '4px',
        boxShadow: 'none',
        borderBottom: 'none',
        '&:hover': {
          borderColor: 'black',
          boxShadow: 'none',
        },
      },
      '& .location__input-container': {
        fontFamily: 'Spartan',
        fontWeight: '500',
        fontSize: '13px',
        color: '#003249',
      },
      '& .location__value-container': {
        padding: '0px',
      },
      '& .location__placeholder': {
        fontFamily: 'Spartan',
        fontWeight: '500',
        fontSize: '13px',
      },
    },
    '& .title': {
      marginBottom: '5px',
      fontFamily: 'Spartan',
      fontWeight: '700',
      fontSize: '13px',
    },
    '& .sub-title': {
      fontFamily: 'Spartan',
      fontWeight: '500',
      fontSize: '13px',
      color: '#003249',
    },
    '& .divider': {
      border: '1px solid #E2E8F0',
      height: '35px',
      margin: 'auto',
    },
    '& .who-wrapper': {
      overflow: 'unset',
      position: 'absolute',
      top: '65px',
      right: '0px',
      zIndex: '1300',
      borderRadius: '16px',
      border: '1px solid #E2E8F0',
      backgroundColor: '#fff',
      '& .who-title': {
        padding: '24px',
        minWidth: '280px',
        cursor: 'default',
        '& .title': {
          fontFamily: 'Spartan',
          fontWeight: '700',
          fontSize: '16px',
          color: '#003249',
        },
      },
      '& .option-title': {
        fontFamily: 'Spartan',
        fontWeight: '700',
        fontSize: '14px',
        color: '#003249',
      },
    },
    '& .searchIcon': {
      color: '#000',
      width: '50px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopRightRadius: '16px !important',
      borderBottomRightRadius: '16px !important',
      '&:hover': {
        backgroundColor: '#F1F5F9',
      },
    },
    '& .dropdown__control': {
      width: '85px',
      borderRadius: '8px',
    },
    '& .dropdown__single-value': {
      fontFamily: 'Spartan',
      fontWeight: '500',
      fontSize: '13px',
      color: '#003249',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '16px',
      '& .divider': {
        display: 'none',
      },
    },
  },
  '& .top': {
    height: '160px',
    position: 'relative',
    borderBottomRightRadius: '16px',
    background: 'linear-gradient(99.09deg, #008AC9 2.64%, #003249 100%)',
    '& .view-profile': {
      paddingTop: '30px',
      paddingLeft: '20px',
      fontFamily: 'Spartan',
      fontWeight: '700',
      fontSize: '16px',
      color: '#fff',
    },
    '& .user-img': {
      position: 'absolute',
      bottom: '-72px',
      height: '120px',
      width: '120px',
      border: '4px solid #DBEAFE',
    },
  },
  '& .name': {
    fontFamily: 'Spartan',
    fontWeight: '700',
    fontSize: '18px',
    color: '#003249',
    marginBottom: '6px',
  },
  '& .duration': {
    fontFamily: 'Spartan',
    fontWeight: '500',
    fontSize: '14px',
    color: '#64748B',
  },
  '& .bold': {
    marginBottom: '5px',
    fontFamily: 'Spartan',
    fontWeight: '700',
    fontSize: '14px',
    color: '#34343C',
  },
  '& .details': {
    fontFamily: 'Spartan',
    fontWeight: '700',
    fontSize: '14px',
    color: '#64748B',
  },
  '& .detail-info': {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
  },
  '& .rating': {
    '& .rating-heading': {
      marginBottom: '20px',
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .review': {
      fontFamily: 'Spartan',
      fontWeight: '700',
      fontSize: '16px',
      color: '#1E293B',
    },
    '& .see-all': {
      cursor: 'pointer',
      display: 'flex',
      alignItem: 'center',
      fontFamily: 'Spartan',
      fontWeight: '700',
      fontSize: '16px',
      color: '#003249',
      '& span': {
        marginRight: '10px',
      },
      '& svg': {
        marginTop: '2px',
      },
    },
  },
});
const CustomDrawer = styled(Drawer)({
  "& .top": {
    overflowX: "hidden",
    padding: "30px 20px 45px",
    width: "570px",
  },
  "& .close-icon": {
    cursor: "pointer"
  },
  "& .review": {
    fontSize: "18px",
    fontFamily: "Spartan",
    fontWeight: "700"
  },
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "16px",
    maxWidth: "550px"
  },
  "& .title": {
    textAlign: "center",
    paddingTop: "35px",
  },
  "& .MuiDialogContent-root": {
    padding: "30px 30px 18px !important"
  },
  "& .dialog-content": {
    textAlign: "center",
    "& .popup-title": {
      letterSpacing: "0px",
      marginBottom: "5px",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249"
    },
    "& .popup-desc": {
      letterSpacing: "-2%",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "15px",
      color: "#94A3B8"
    },
  },
  "& .dialog-action": {
    gap: "10px",
    padding: "30px",
    justifyContent: "center",
  },
  "& .close-btn": {
    color: "#003249",
    height: "15px",
    width: "15px",
  },
  "&.change-host": {
    "& .MuiDialogContent-root": {
      paddingTop: "15px !important",
      paddingBottom: "15px !important",
    }
  }
})

const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
  inputHeader: {
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-2%',
    color: ' #64748B'
    
  },
  submitButton: {
    height: '44px',
    backgroundColor: '#003249',
    width: '100%',
    borderRadius: '8px',
    marginTop: '40px'
  },
  btnText: {
    color: '#FFFFFF',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  note: {
    color: ' #64748B',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none',
    marginTop: '14',
    alignSelf: 'center'
  },
  infoItem: {
    color: '#475569',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '5px',
  },
  infoIcons: {
    height: '16px',
  },
  sendButton: {
    height: '44px',
    width: '100%',
    borderRadius: '8px',
    border: '1px solid #003249',
    marginTop: '40px'
  },
  sendText: {
    color: ' #003249',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  profileText: {
    color: ' #003249',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  profileInfo: {
    color: ' #475569',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  discText: {
    color: '#64748B',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    textTransform: 'none'
  },
  cardContainer: {
    marginTop: '64px'
  },
  cardTitle: {
    color: ' #1E293B',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '-3%',
    marginLeft: '20px',
    marginBottom: '24px'
  },
  locationText: {
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-3%',
    color: '#475569',
    paddingBottom: '10px'
  },
  ammenitiesText: {
    color: '#003249',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  seeAllText: {
    color: '#003249',
    fontFamily: 'Spartan',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '8px'
  },
  titleInfo: {
    color: '#64748B',
    fontFamily: 'Spartan',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center'
  },
  infoItemContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems: 'center',
    alignContent:'center',
    padding:'4px',
    margin:'4px'
  },
  center:{
    justifyContent:'center',
    alignItems:'center',
  }
};
const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&:disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border:'none'
  }
});
const ReviewCard = styled(Box)<{ bgcolor?: string }>(({ bgcolor }) => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  padding: "10px",
  borderRadius: "16px",
  border: "1px solid #E2E8F0",
  backgroundColor: `${bgcolor ? bgcolor : "#F1F5F9"}`,
  "& .card-img": {
    height: "37px",
    width: "37px"
  },
  "& .user-name": {
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "15px",
    color: "#334155",
  },
  "& .date": {
    fontFamily: "Spartan",
    fontWeight: "400",
    fontSize: "11px",
    color: "#475569",
  },
  "& .rating": {
    fontFamily: "Spartan",
    fontWeight: "700",
    fontSize: "14px",
    color: "#003249",
    "& span": {
      marginLeft: "5px"
    }
  },
  "& .review-text": {
    color: "#475569",
    fontFamily: "Spartan",
    fontWeight: "500",
    fontSize: "13px",
    "& span": {
      marginLeft: "5px",
      cursor: "pointer",
      color: "#475569",
      fontFamily: "Spartan",
      fontWeight: "500",
      fontSize: "14px",
      textDecoration: "underline",
    }
  }
}));
// Customizable Area End
