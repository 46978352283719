// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../../../../components/src/NavigationRouteWrapper/helpers";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface IFile {
  file_url: string;
  file_name: string;
}

export interface IAccommodation {
  name: string;
  guest_capacity: number;
  price_per_night: number;
  selected_item_price: number;
  selected_guest_capacity: number;
  selected_spot_capacity: number;
  hookups_type: string;
}

export interface ICampsite {
  selected_guest_capacity: number;
}

export interface IHookup {
  hookups_type: string;
  selected_spot_capacity: number;
}

export interface IAmenities {
  type: string;
  selected_horse: number;
  selected_item_price: number;
}

export interface IHorseAcc {
  type: string;
  selected_stall: number;
  selected_item_price: number;
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isLoading: boolean;
  data: any;
  role: string | null;
  showPopup:boolean,
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class BookingDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getBookingInfoApiCallId: string = "";
  cancelBookingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      isLoading: false,
      data: null,
      showPopup:false,
      role: localStorage.getItem("role")
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getBookingInfoApiCallId) {
        if (responseJson.data) {
          this.setState({ data: responseJson.data });
        }
        this.setState({ loading: false });
      } else if (apiRequestCallId === this.cancelBookingApiCallId) {
        if (responseJson.message === "Booking cancelled successfully") {
          toast.success(responseJson.message);
          this.props.navigation.navigate("ManageBooking");
        } else if (responseJson.error) {
          toast.error(responseJson.error);
        }
        this.setState({ isLoading: false });
      }
    }
  }

  async componentDidMount() {
    const id = this.props.navigation.getParam('bookingId');
    if (id) {
      this.getBookingInfo(id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const id = this.props.navigation.getParam('bookingId');
    if (prevProps.navigation.getParam('bookingId') !== id) {
      this.getBookingInfo(id);
    }
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  redirectToListing = () => {
    this.props.navigation.navigate("ProductDescription", { path: { productId: this.state.data.attributes.listing.data.id } });
  }

  guestAccommodationsInfo = () => {
    const { booking_apartment, booking_campsite, booking_hookups } = this.state.data.attributes;

    const formatAccommodation = (items: IAccommodation[], key: "name" | "hookups_type", capacityKey: "selected_guest_capacity" | "selected_spot_capacity") =>
      items?.map(item => `${item[key]}: ${item[capacityKey]} guest${item[capacityKey] > 1 ? "s" : ""}`).join(" • ") || "";

    const apartmentInfo = formatAccommodation(booking_apartment || [], "name", "selected_guest_capacity");
    const campsiteInfo = formatAccommodation(booking_campsite || [], "name", "selected_guest_capacity");
    const hookupInfo = formatAccommodation(booking_hookups || [], "hookups_type", "selected_spot_capacity");

    return [apartmentInfo, campsiteInfo, hookupInfo].filter(Boolean).join(" • ");
  }

  horseStablingInfo = () => {
    const { booking_horse_accommodations } = this.state.data.attributes;
    let str = '';
    if (booking_horse_accommodations?.length > 0) {
      booking_horse_accommodations.forEach((item: IHorseAcc, index: number) => {
        str += (index > 0 ? ' • ' : '') + item.type + ': ' + item.selected_stall + (item.selected_stall > 1 ? ' horses' : ' horse');
      });
    }
    return str;
  }

  amenitiesInfo = () => {
    const { booking_amenities } = this.state.data.attributes;
    let str = '';
    if (booking_amenities?.length > 0) {
      booking_amenities.forEach((item: IAmenities, index: number) => {
        str += (index > 0 ? ' • ' : '') + item.type + ': ' + item.selected_horse + (item.selected_horse > 1 ? ' horses' : ' horse');
      });
    }
    return str;
  }

  changeRequest = () => {
    const productId = this.props.navigation.getParam('bookingId');
    localStorage.setItem("isBookingReqChange", 'YES');
    this.props.navigation.navigate("GuestBookingForm", { path: { productId } });
  }

  cancelBooking = async () => {
    this.setState({ isLoading: true });
    const id = this.props.navigation.getParam('bookingId');
    const header = {
      token: localStorage.getItem("token")
    }
    this.cancelBookingApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_request_management/bookings/cancel_booking?booking_id=${id}`,
    });
  }

  rejectBooking = () => {
    this.setState({ showPopup: true })
  }

  acceptBooking = () => {
    this.setState({ showPopup: true })
  }

  isChangeRequested = () => this.state.role === 'host' && this.state.data.attributes.status === 'Change requested';

  getBookingInfo = async (id: string) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token")
    }
    this.getBookingInfoApiCallId = await apiCall({
      header,
      method: "GET",
      endPoint: `/bx_block_request_management/bookings/${id}`,
    });
  }
  // Customizable Area End
}