// Customizable Area Start
import React from "react";
import {
  Box, Typography, CircularProgress, Button, Card, CardContent, CardMedia,
  Avatar, Accordion, AccordionSummary, AccordionDetails, IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Slider from "react-slick";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import BookingDetailController, { IFile, IAmenities, IHorseAcc } from "./BookingDetailController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End

export default class BookingDetail extends BookingDetailController {
  // Customizable Area Start

  getColor = (status: string) => {
    let color = "orange";
    if (status === "Confirmed" || status === "Pending") {
      color = "blue";
    } else if (status === "Cancelled_by_guest" || status === "Cancelled_by_host") {
      color = "red";
    } else if (status === "Active") {
      color = "active";
    }
    return color;
  }

  getStatus = (status: string) => {
    const { role } = this.state;
    const statusMap: Record<string, string> = {
      cancelled_by_host: role !== "guest" ? "Cancelled By You" : "Cancelled by Host",
      cancelled_by_guest: role === "guest" ? "Cancelled By You" : "Cancelled By Guest",
    };
    return statusMap[status.toLowerCase()]
      || status.split(/[_\s]+/).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  }

  getImage = (name: string, url: string) => {
    return (
      <CardMedia
        key={name}
        component="img"
        className="image"
        height="185"
        alt={name}
        image={url}
      />
    );
  }

  renderBasicInfo = () => {
    const data = this.state.data.attributes;
    const listing = data.listing.data.attributes;
    return (
      <CustomCard className="basic-info">
        <Box width="300px">
          <Box className="slider">
            <Slider {...{
              dots: true,
              infinite: true,
              customPaging: () => <Dot />,
            }}>
              {listing.cover_photo && this.getImage(listing.cover_photo.filename, listing.cover_photo.url)}
              {Array.isArray(listing.files) && listing.files.map((file: IFile) => {
                return this.getImage(file.file_name, file.file_url)
              })}
            </Slider>
          </Box>
        </Box>
        <CardContent className="content">
          <Typography className="title">{listing.title}</Typography>
          <Typography className="address">{listing.address}</Typography>
          <Typography className="blur-text">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {data.total_nights > 1 ? "nights" : "night"})</Typography>
          <Typography className="blur-text">{listing.total_guest} {listing.total_guest > 1 ? "Guests" : "Guest"} • {listing.total_horse} {listing.total_horse > 1 ? "Horses" : "Horse"}</Typography>
          <Typography className={`status ${this.getColor(data.status)}`}>{this.getStatus(data.status)}</Typography>
        </CardContent>
      </CustomCard>
    );
  }

  renderBookingId = () => {
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Booking ID
            <span>{this.state.data.attributes.booking_id}</span>
          </Typography>
          <Box pl="5px">
            <Box className="items">
              <Typography className="sub-title">View Listing</Typography>
              <IconButton onClick={this.redirectToListing}>
                <KeyboardArrowRightIcon sx={{ color: "000" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderImportantInfo = () => {
    const { check_in_time, check_out_time } = this.state.data.attributes;
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Important Info
          </Typography>
          <Box display="flex" flexDirection="column" gap="15px">
            <Box className="sub-wrap">
              <AccessTimeIcon />
              <Typography className="sub-title">Check in time {check_in_time}</Typography>
            </Box>
            <Box className="sub-wrap">
              <AccessTimeIcon />
              <Typography className="sub-title">Check out time {check_out_time}</Typography>
            </Box>
            <Box className="sub-wrap between">
              <Box className="sub-wrap">
                <AttachMoneyIcon />
                <Typography className="sub-title">10% Service Fee</Typography>
              </Box>
              <Typography className="link">Cancellation & Refund Policy</Typography>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderTripInfo = () => {
    const data = this.state.data.attributes;
    const accommodation = this.guestAccommodationsInfo();
    const horseStabling = this.horseStablingInfo();
    const amenities = this.amenitiesInfo();
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Check the trip information
          </Typography>
          <Box>
            {this.isChangeRequested() ? <><Box mb="5px">
              <Typography className="trip-title">Dates</Typography>
              <Typography className="trip-sub-striked">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {data.total_nights > 1 ? "nights" : "night"})</Typography>
            </Box><Box mb="15px">
                <Typography className="trip-sub">{moment(data.request_change_start_date).format("MMM DD/YY")} - {moment(data.request_change_start_date).format("MMM DD/YY")} ({data.total_nights} {data.total_nights > 1 ? "nights" : "night"})</Typography>
              </Box></> :
              <Box mb="15px">
                <Typography className="trip-title">Dates</Typography>
                <Typography className="trip-sub">{moment(data.start_date).format("MMM DD/YY")} - {moment(data.end_date).format("MMM DD/YY")} ({data.total_nights} {data.total_nights > 1 ? "nights" : "night"})</Typography>
              </Box>}
            {accommodation && (
              <Box mb="15px">
                <Typography className="trip-title">Guests accommodations</Typography>
                <Typography className="trip-sub">{accommodation}</Typography>
              </Box>
            )}
            {horseStabling && (
              <Box mb="15px">
                <Typography className="trip-title">Horses stabling</Typography>
                <Typography className="trip-sub">{horseStabling}</Typography>
              </Box>
            )}
            {amenities && (
              <Box>
                <Typography className="trip-title">Amenities</Typography>
                <Typography className="trip-sub">{amenities}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CustomCard>
    );
  }

  renderHostInfo = () => {
    const hostInfo = this.state.data.attributes.host_account;
    return (
      <CustomCard>
        <Box p="10px">
          <Typography className="card-title">
            Host info
          </Typography>
          <Box mt="25px" display="flex" justifyContent="space-between">
            <Box className="user-info">
              <Avatar className="avatar" src="https://s3-alpha-sig.figma.com/img/cd2b/6c3b/650828cfeeb35cf7979b40e2b37a600f?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=BxSSRtPwYMSmko0kyKMYQyzEPVhR1Chb8I9x9N3kuN26~lmPMtTtahMsbzkG7iighrBvn16UXaCpeWPapWX8HyB3Fe1rUc-H3rU~pLhZdnRpIJhCJCduoeZN85oUY5MKoAo0buvf1aUo1azDioMDNAn109m6Zc~2DhUeIdduVTcm~nJRZGiKt6xubWZwdimeLoZ17BZ573nAqdMSxNNz3WyToCCUMW44HVrrFBNQpUp~3T6~oXYeKQJbCyCRM6pKsnQ64n9sAlqtdJLtO7c8ripsToEto22Aocdo2jmTE~VyF0swLchXG84SDCx4wavywi8SpoQ6hILC5Wa9bgmipw__" />
              <Box>
                <Typography className="user-name">{hostInfo.first_name + " " + hostInfo.last_name}</Typography>
                <Typography className="info">{hostInfo.listings_count} listing • {hostInfo.reviews_count} Ratings</Typography>
              </Box>
            </Box>
            <Box className="rating">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91692 9.92509C2.08644 10.0545 2.29127 10.0138 2.54202 9.82155L5 7.93564L7.45798 9.82155C7.70873 10.0138 7.91709 10.0545 8.08308 9.92509C8.24553 9.79567 8.28438 9.58119 8.18549 9.27057L7.22137 6.24571L9.69701 4.38198C9.94775 4.19339 10.0466 3.9974 9.97954 3.79032C9.91597 3.59063 9.73233 3.4834 9.41448 3.48709L6.37732 3.50558L5.44851 0.46963C5.35316 0.155311 5.20483 0 5 0C4.79517 0 4.64684 0.155311 4.55149 0.46963L3.62268 3.50558L0.585516 3.48709C0.271205 3.4834 0.0840309 3.59063 0.0204624 3.79032C-0.0466378 3.9974 0.0522467 4.19339 0.302989 4.38198L2.77863 6.24571L1.81804 9.27057C1.71562 9.58119 1.75447 9.79567 1.91692 9.92509Z" fill="#003249" />
              </svg>
              <span>{hostInfo.average_rating_count}</span>
            </Box>
            <Box>
              <WhiteButton sx={{ width: "250px" }}>Send a Message </WhiteButton>
            </Box>
          </Box>
        </Box>
      </CustomCard>
    );
  }

  formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value).replace('$', '$ ');
  }

  renderPaymentDetails = () => {
    const data = this.state.data.attributes;
    return (
      <Box className="payment-detail">
        <Typography className="title">Payment Detail</Typography>
        <Typography className="nights">{data.total_nights} {data.total_nights > 1 ? "nights" : "night"}</Typography>
        <Box pl="20px">
          {!!data.booking_price_breakdown.horse_accommodations && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Horse stabling</Typography>
                  <Typography className="text">{this.formatCurrency(data.booking_price_breakdown.horse_accommodations)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {data.booking_horse_accommodations.map((item: IHorseAcc) =>
                  <Box className="price-wrap">
                    <Typography className="price">{item.type}</Typography>
                    <Typography className="price">{this.formatCurrency(item.selected_item_price)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          {!!data.booking_price_breakdown.guest_accommodations && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Guest accommodation</Typography>
                  <Typography className="text">{this.formatCurrency(data.booking_price_breakdown.guest_accommodations)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {!!data.booking_price_breakdown.breakdown_guest_accommodations.apartment && (
                  <Box className="price-wrap">
                    <Typography className="price">Apartment Guests </Typography>
                    <Typography className="price">{this.formatCurrency(data.booking_price_breakdown.breakdown_guest_accommodations.apartment)}</Typography>
                  </Box>
                )}
                {!!data.booking_price_breakdown.breakdown_guest_accommodations.campsite && (
                  <Box className="price-wrap">
                    <Typography className="price">Camping Spots</Typography>
                    <Typography className="price">{this.formatCurrency(data.booking_price_breakdown.breakdown_guest_accommodations.campsite)}</Typography>
                  </Box>
                )}
                {!!data.booking_price_breakdown.breakdown_guest_accommodations.hookups && (
                  <Box className="price-wrap">
                    <Typography className="price">Hookup Options</Typography>
                    <Typography className="price">{this.formatCurrency(data.booking_price_breakdown.breakdown_guest_accommodations.hookups)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          {!!data.booking_price_breakdown.amenities && (
            <CustomAccordion defaultExpanded>
              <CustomAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#003249' }} />}>
                <Box className="wrap-info">
                  <Typography className="text">Amenities</Typography>
                  <Typography className="text">{this.formatCurrency(data.booking_price_breakdown.amenities)}</Typography>
                </Box>
              </CustomAccordionSummary>
              <CustomAccordionDetails>
                {data.booking_amenities.map((item: IAmenities) =>
                  <Box className="price-wrap">
                    <Typography className="price">{item.type}</Typography>
                    <Typography className="price">{this.formatCurrency(item.selected_item_price)}</Typography>
                  </Box>
                )}
              </CustomAccordionDetails>
            </CustomAccordion>
          )}
          <Box className="price-wrap">
            <Typography className="text">Platform Fee 10%</Typography>
            <Typography className="text">{this.formatCurrency(data.booking_price_breakdown.platform_fee)}</Typography>
          </Box>
        </Box>
        <Box mt="40px" className="price-wrap">
          <Typography className="total">TOTAL</Typography>
          <Typography className="total">{this.formatCurrency(data.booking_price_breakdown.total)}</Typography>
        </Box>
        {this.isChangeRequested() && <>
          <Box textAlign="center" sx={{marginTop:'62px'}}>
          <Typography className="text">You have received a booking update request</Typography>
            <Typography className="request-info">Please review and approve or decline it. If approved, the guest will need to complete the payment within 48 hours to confirm the change.</Typography>
          </Box>
          <Box display="flex" gap="30px">
            <WhiteButton data-test-id="cancel-btn" onClick={this.rejectBooking}>Reject</WhiteButton>
            <BlackButton data-test-id="change-btn" onClick={this.acceptBooking}>Accept</BlackButton>
          </Box></>
        }
        {this.state.role==='guest' && <>
       <Box textAlign="center">
          <Typography className="request-text">Changes are up to the host; they must accept your request, which could affect the final price.</Typography>
        </Box>
        <Box display="flex" gap="30px">
          <WhiteButton data-test-id="cancel-btn" onClick={this.cancelBooking}>Cancel Booking</WhiteButton>
          <BlackButton data-test-id="change-btn" onClick={this.changeRequest}>Request Change</BlackButton>
        </Box></>
        }
        
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Booking">
        <Loader loading={this.state.isLoading} />
        <Wrapper>
          {this.state.loading ? (
            <Box className="loading">
              <CircularProgress sx={{ color: "#000" }} />
            </Box>
          ) : (
            this.state.data &&
            <>
              <Box display="flex" height="calc(100vh - 75px)">
                <Box className="left-wrapper">
                  <Box className="column">
                    <Typography className="booking" onClick={this.onBack}>
                      <ArrowBackIcon />
                      <span>Booking</span>
                    </Typography>
                    {this.renderBasicInfo()}
                    {this.renderBookingId()}
                    {this.renderImportantInfo()}
                    {this.renderTripInfo()}
                    {this.renderHostInfo()}
                  </Box>
                </Box>
                <Box>
                  {this.renderPaymentDetails()}
                </Box>
              </Box>
            </>
          )}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  backgroundColor: "#F8FAFC",
  '& .loading': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  "& .left-wrapper": {
    flex: "1",
    overflow: "auto",
    padding: "30px 90px 40px 30px",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px"
    },
    "& .booking": {
      width: "fit-content",
      fontFamily: "Spartan",
      fontWeight: "700",
      fontSize: "18px",
      color: "#003249",
      textTransform: "uppercase",
      display: "flex",
      cursor: "pointer",
      "& span": {
        marginLeft: "20px",
      }
    },
    "& .column": {
      display: "flex",
      gap: "20px",
      flexDirection: "column"
    },
  },
  "& .payment-detail": {
    width: "500px",
    height: "100%",
    overflow: "scroll",
    padding: "40px 20px 40px",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    boxShadow: "-2px 0px 8px 0px #00000014",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px"
    },
    "& .title": {
      fontFamily: 'Spartan',
      fontWeight: "700",
      fontSize: '16px',
      color: '#003249',
      textTransform: "uppercase",
      letterSpacing: '-0.5px',
    },
    "& .nights": {
      marginTop: "30px",
      marginBottom: "30px",
      fontFamily: 'Spartan',
      letterSpacing: '-0.5px',
      fontWeight: "500",
      fontSize: '16px',
      color: '#003249',
    },
    "& .text": {
      marginTop: "2px",
      fontFamily: 'Spartan',
      fontWeight: "700",
      fontSize: '14px',
      color: '#003249',
      letterSpacing: '-0.5px',
    },
    "& .price-wrap": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: '44px',
      marginBottom: '8px',
      "& .total": {
        fontFamily: 'Spartan',
        fontWeight: "700",
        fontSize: '16px',
        color: '#003249',
      }
    },
    "& .price": {
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#003249',
    },
    "& .wrap-info": {
      width: '100%',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    "& .request-text": {
      marginTop: "35px",
      marginBottom: "25px",
      letterSpacing: "0px",
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#64748B',
    },
    "& .request-info": {
      marginBottom: "25px",
      letterSpacing: "0px",
      fontFamily: 'Spartan',
      fontWeight: "500",
      fontSize: '14px',
      color: '#64748B',
    }
  }
});

const CustomCard = styled(Card)({
  padding: "10px",
  borderRadius: "16px",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 8px 0px #00000014",
  "&.basic-info": {
    display: "flex",
    gap: "20px",
  },
  "& .slider": {
    "& .slick-slider .slick-list": {
      borderRadius: "16px",
      height: "185px",
    },
    "& .slick-prev, .slick-next": {
      display: "none !important",
    },
    ".slick-dots": {
      padding: 0,
      margin: 0,
      listStyle: "none",
      position: "absolute",
      bottom: "14px",
      display: "flex !important",
      justifyContent: "center",
    },
    ".slick-dots li": {
      width: "12px",
      height: "12px",
      display: "flex",
      margin: "0px 1px",
      alignItems: "center"
    },
    ".slick-dots li div": {
      width: "6px",
      height: "6px",
      opacity: "0.5",
      backgroundColor: "#fff"
    },
    ".slick-dots li button": {
      opacity: 0,
      cursor: "pointer",
    },
    ".slick-dots li.slick-active div": {
      width: "8px",
      opacity: "1",
      height: "8px",
      backgroundColor: "#fff",
    },
  },
  "& .image": {
    borderRadius: "16px",
  },
  "& .content": {
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .status": {
      letterSpacing: "-0px",
      width: "fit-content",
      fontSize: "11px",
      fontFamily: "Spartan",
      fontWeight: "500",
      padding: "3px 10px",
      borderRadius: "20px",
      color: "#B45309",
      backgroundColor: "#FFE5C7",
      "&.green": {
        color: "#059669",
        backgroundColor: "#ECFDF5",
      },
      "&.blue": {
        color: "#2563EB",
        backgroundColor: "#DBEAFE",
      },
      "&.active": {
        color: "#06D6A0",
        backgroundColor: "#ECFDF5",
      },
      "&.red": {
        color: "#DC2626",
        backgroundColor: "#FEE2E2"
      },
    }
  },
  "& .title": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#003249",
    marginBottom: "10px"
  },
  "& .address": {
    marginBottom: "7px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249"
  },
  "& .blur-text": {
    marginBottom: "10px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#475569"
  },
  "& .card-title": {
    marginBottom: "15px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "20px",
    fontWeight: "700",
    color: "#003249",
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: "10px",
      fontSize: "14px",
      fontWeight: "500",
    }
  },
  "& .items": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .sub-title": {
    letterSpacing: "-0.5px",
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .sub-wrap": {
    display: "flex",
    "& svg": {
      marginTop: "2px",
      marginRight: "5px",
      fill: "#475569",
      fontSize: "14px"
    },
    "&.between": {
      justifyContent: "space-between"
    }
  },
  "& .link": {
    cursor: "pointer",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#3B82F6",
  },
  "& .trip-title": {
    marginBottom: "5px",
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#003249",
  },
  "& .trip-sub": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .trip-sub-striked": {
    fontFamily: "Spartan",
    letterSpacing: "-0.5px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
    textDecoration: "line-through",
  },
  "& .rating": {
    marginTop: "5px",
    "& span": {
      marginLeft: "4px",
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontSize: "14px",
      fontWeight: "700",
      color: "#003249",
    }
  },
  "& .user-info": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& .avatar": {
      width: "44px",
      height: "44px",
      border: "1.5px solid #E0E7FF",
    },
    "& .user-name": {
      letterSpacing: "-0.5px",
      color: "#003249",
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "5px"
    },
    "& .info": {
      letterSpacing: "-0.5px",
      color: "#475569",
      fontFamily: "Spartan",
      fontSize: "14px",
      fontWeight: "500",
    }
  }
});

const Dot = styled("div")({
  width: "10px",
  height: "10px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  transition: "background-color 0.3s ease",
});

const CustomAccordion = styled(Accordion)({
  border: 'none !important',
  boxShadow: 'none !important',
  padding: '0px',
  margin: '0px',
  marginBottom: "16px",
  position: 'relative',
  '&::before': {
    position: 'absolute',
    left: 0,
    top: '-1px',
    right: 0,
    height: '1px',
    content: '""',
    opacity: 1,
    backgroundColor: 'transparent',
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  "&.MuiAccordion-root.Mui-expanded": {
    margin: "0px",
    marginBottom: "16px"
  }
});

const CustomAccordionSummary = styled(AccordionSummary)({
  padding: '0px',
  margin: '0px',
  outline: 'none',
  minHeight: "auto",
  border: 'none !important',
  boxShadow: 'none !important',
  "& .MuiAccordionSummary-content,& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px",
  },
  "&.Mui-expanded": {
    margin: "0px",
    marginBottom: "10px",
    minHeight: "auto",
  },
});

const CustomAccordionDetails = styled(AccordionDetails)({
  padding: '0px',
  margin: '0px',
  marginLeft: '10px',
  border: 'none !important',
  boxShadow: 'none !important',
  outline: 'none',
});

const WhiteButton = styled(Button)({
  width: "100%",
  padding: "6px 13px",
  fontFamily: "Spartan",
  color: "#003249",
  fontSize: "16px",
  letterSpacing: "-0.5px",
  textTransform: "none",
  whiteSpace: "nowrap",
  borderRadius: "8px",
  backgroundColor: "#fff",
  fontWeight: "500",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#003249",
    backgroundColor: "#fff",
  }
});

const BlackButton = styled(Button)({
  width: "100%",
  padding: "10px 16px",
  borderRadius: "8px",
  letterSpacing: "-0.5px",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: "Spartan",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  }
});
// Customizable Area End