// Customizable Area Start
import React from "react";
import {
  Typography, Box, Grid, TextField, styled, FormControl, FormLabel,
  Button, Dialog, InputAdornment, IconButton, Checkbox, Radio
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PhoneInput from 'react-phone-input-2';

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import Loader from "../../../components/src/Loader.web";
const bgImage = require("../assets/bgImage.png").default;
const roleBgImage = require("../assets/role-bg-image.jpg").default;
import 'react-phone-input-2/lib/material.css';

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  renderRoleUI = () => {
    return (
      <Box className="section">
        <Box className="left-section">
          <img className="image" src={roleBgImage} />
          <Box className="back-btn" data-test-id="back-btn" onClick={this.onGoBack}>
            <ChevronLeftIcon />
          </Box>
          <Box className="text-wrapper">
            <Typography className="text">Find the Ideal Haven for Your Horse</Typography>
            <Typography className="sub-text">Explore trusted, short-term stabling <br />solutions with ease.</Typography>
          </Box>
        </Box>
        <Box className="right">
          <Box className="right-section">
            <Box maxWidth="450px" height="fit-content">
              <Typography className="title">
                Will you mainly use the app as a guest or a host?
              </Typography>
              <Typography className="sub-title">
                Please select your role.
              </Typography>
              <Box className="card-wrapper">
                <Box className="card" data-test-id="host" onClick={(e) => this.onChangeRole("host")}>
                  <Box>
                    <CustomRadio
                      checked={this.state.role === "host"}
                      icon={<span className="icon" />}
                      checkedIcon={<span className="checked-icon" />}
                    />
                  </Box>
                  <Box>
                    <Typography className={`title ${this.state.role === "host" && "selected"}`}>Continue as a Host</Typography>
                    <Typography className="sub-title">Maecenas dignissim justo eget nulla rutru.</Typography>
                  </Box>
                </Box>
                <Box className="card" data-test-id="guest" onClick={(e) => this.onChangeRole("guest")}>
                  <Box>
                    <CustomRadio
                      checked={this.state.role === "guest"}
                      icon={<span className="icon" />}
                      checkedIcon={<span className="checked-icon" />}
                    />
                  </Box>
                  <Box>
                    <Typography className={`title ${this.state.role === "guest" && "selected"}`}>Continue as a Gest</Typography>
                    <Typography className="sub-title">Maecenas dignissim justo eget nulla rutru.</Typography>
                  </Box>
                </Box>
                <BlackButton disabled={!this.state.role} data-test-id="next-btn" onClick={this.onNext}>Next</BlackButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  getIcon = (isTrue: boolean) => {
    return !isTrue ? (
      <VisibilityOutlinedIcon sx={{ color: "#94A3B8" }} />
    ) : (
      <VisibilityOffOutlinedIcon sx={{ color: "#94A3B8" }} />
    )
  }

  isDisabled = () => {
    return this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? false : true;
  }

  getBgColor = () => {
    return this.state.firstName && this.state.lastName && this.state.email && this.state.phone && this.state.newPassword && this.state.confirmPassword && this.state.checked ? "#003249" : '#94A3B8';
  }

  render() {
    const {
      isUppercaseValid,
      isLengthValid,
      isNumberValid,
      isSpecialCharValid,
      isEmailValid,
      isPhnNoValid,
    } = this.state;
    return (
      <Wrapper>
        <Loader loading={this.state.loading} />
        {this.state.isRolePageVisible
          ? this.renderRoleUI()
          : (
            <Box className="section">
              <Box className="left-section">
                <img className="image" src={bgImage} />
                <Box className="back-btn" data-test-id="back-btn" onClick={this.onBackToRole}>
                  <ChevronLeftIcon />
                </Box>
                <Box className="text-wrapper">
                  <Typography className="text">Find Your Horse's Perfect Stay</Typography>
                  <Typography className="sub-text">Discover reliable, temporary stabling<br /> options effortlessly.</Typography>
                </Box>
              </Box>
              <Box className="right">
                <Box className="right-section">
                  <Box height="fit-content">
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Box>
                          <Typography
                            style={{
                              fontFamily: "Spartan",
                              fontSize: "30px",
                              fontWeight: 700,
                              lineHeight: "40px",
                              letterSpacing: "-0.03em",
                              textAlign: "left",
                              color: "#003249",
                            }}>
                            Create your account
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: "Spartan",
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "24px",
                              letterSpacing: "-0.02em",
                              textAlign: "left",
                              color: "#475569",
                              marginTop: "12px",
                            }}>
                            Already have an account?
                            <span
                              onClick={this.navigateToLoginPage}
                              data-test-id="navigateToLoginPage"
                              style={{
                                cursor: "pointer",
                                fontFamily: "Spartan",
                                fontSize: "16px",
                                fontWeight: 700,
                                lineHeight: "24px",
                                letterSpacing: "-0.03em",
                                textAlign: "left",
                                textDecoration: "underline",
                                marginLeft: "8px",
                                color: "#003249",
                              }}>
                              Log in
                            </span>
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: "520px",
                            height: "492px",
                            gap: "16px",
                            marginTop: "40px",
                          }}>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%"
                            }}>
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left", width: "256px" }}>
                              <Typography
                                style={{
                                  fontFamily: "Spartan",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "22px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: "#64748B",
                                }}>
                                First name
                              </Typography>
                              <CustomTextfield
                                onChange={this.handleFirstName}
                                value={this.state.firstName}
                                data-test-id="handleFirstName"
                                placeholder="Enter your first name"
                                type="first name"
                                name="first name"
                                variant="outlined"
                              />
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "left", marginLeft: "8px", width: "256px" }}>
                              <Typography
                                style={{
                                  fontFamily: "Spartan",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "22px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  color: "#64748B",
                                }}>
                                Last name
                              </Typography>
                              <CustomTextfield
                                onChange={this.handleLastName}
                                value={this.state.lastName}
                                data-test-id="handleLastName"
                                placeholder="Enter your last name"
                                type="last name"
                                name="last name"
                                variant="outlined"
                              />
                            </Box>
                          </Box>
                          <Box
                            style={{
                              width: "520px",
                              height: "82px",
                            }}>
                            <Typography
                              style={{
                                fontFamily: "Spartan",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                letterSpacing: "-0.02em",
                                textAlign: "left",
                                marginTop: "16px",
                                color: "#64748B",
                              }}>
                              Email address
                            </Typography>
                            <CustomTextfield
                              onChange={this.handleEmailChange}
                              value={this.state.email}
                              data-test-id="handleEmailChange"
                              error={!!this.state.emailError}
                              placeholder="mail@example.com"
                              type="email"
                              name="email"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CheckIcon
                                      style={this.getCheckIconStyle(isEmailValid)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {this.state.emailError != "" &&
                              <p style={{
                                color: "#DC2626",
                                fontFamily: "Spartan !important",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "20px",
                                letterSpacing: "-0.02em",
                                textAlign: "left",
                                marginTop: "-2px",
                              }}>
                                {this.state.emailError}
                              </p>
                            }
                          </Box>
                          <Box style={{ width: "520px", height: "82px", marginTop: "16px" }}>
                            <FormControl fullWidth >
                              <StyledFormLabel htmlFor="phoneNumber">
                                Phone number
                              </StyledFormLabel>
                              <PhoneInput
                                inputProps={{
                                  id: "phoneNumber",
                                  name: "phoneNumber",
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {isPhnNoValid && <CheckIcon style={{ color: '#10B981' }} />}
                                    </InputAdornment>
                                  ),
                                }}
                                country="us"
                                data-test-id="handlePhoneNumber"
                                value={this.state.phone}
                                onChange={this.handlePhoneNumber}
                                enableSearch={true}
                                disableSearchIcon={false}
                                countryCodeEditable={true}
                              />
                              {this.state.phoneError != "" &&
                                <p style={{
                                  color: "#DC2626",
                                  fontFamily: "Spartan !important",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "20px",
                                  letterSpacing: "-0.02em",
                                  textAlign: "left",
                                  marginTop: "-2px",
                                }}>
                                  {this.state.phoneError}
                                </p>
                              }
                            </FormControl>
                          </Box>
                          <Box style={{ width: "520px", height: "82px", marginTop: "16px" }}>
                            <Typography
                              style={{
                                fontFamily: "Spartan",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "22px",
                                letterSpacing: "-0.02em",
                                textAlign: "left",
                                color: "#64748B",
                                marginTop: "20px"
                              }}>
                              Password
                            </Typography>
                            <CustomTextfield
                              onChange={this.handleNewPassword}
                              value={this.state.newPassword}
                              data-test-id="handleNewPassword"
                              error={!!this.state.newPasswordError}
                              placeholder="Enter your password"
                              type={this.getInputType(this.state.enableNewPasswordField)}
                              name="password"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={this.handleShowNewPassword}
                                      data-test-id="handleShowNewPassword"
                                      edge="end"
                                    >
                                      {this.getIcon(this.state.enableNewPasswordField)}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box style={{ width: "520px", height: "82px", marginTop: "16px", }}>
                            <Typography
                              style={{
                                lineHeight: "22px", letterSpacing: "-0.02em",
                                textAlign: "left", color: "#64748B", fontFamily: "Spartan",
                                fontSize: "14px", fontWeight: 700,
                              }}
                            >
                              Confirm password
                            </Typography>
                            <CustomTextfield
                              onChange={this.handleConfirmPassword}
                              value={this.state.confirmPassword}
                              data-test-id="handleConfirmPassword"
                              error={!!this.state.confirmPasswordError}
                              placeholder="Confirm your password"
                              type={this.getInputType(this.state.enableConfirmPasswordField)}
                              name="confirm password"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={this.handleShowConfirmPassword}
                                      data-test-id="handleShowConfirmPassword"
                                      edge="end"
                                    >
                                      {this.getIcon(this.state.enableConfirmPasswordField)}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography
                              style={{
                                fontFamily: "Spartan",
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: "-0.02em",
                                textAlign: "left",
                                color: "#DC2626",
                              }}>
                              {this.state.confirmPasswordError}
                            </Typography>
                          </Box>
                        </Box>
                        {this.state.newPassword && (
                          <Box
                            style={{ width: "520px", marginTop: "4px" }}>
                            <Box style={{ display: "flex", width: "186px", height: "18px" }}>
                              <Typography
                                style={{
                                  fontFamily: "Spartan",
                                  fontSize: "12px",
                                  fontWeight: 700,
                                  lineHeight: "18px",
                                  textAlign: "left",
                                  color: "#64748B",
                                  letterSpacing: "-0.02em",
                                }}>
                                Your password must contain
                              </Typography>
                            </Box>
                            <Box style={{ width: "520px", marginTop: "4px" }}>
                              <Box style={{ display: "flex", alignItems: "center" }}>
                                {isUppercaseValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{ color: 'Red', marginRight: '8px' }} />}
                                <Typography
                                  style={{
                                    fontFamily: "Spartan",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "18px",
                                    letterSpacing: "-0.02em",
                                    textAlign: "left",
                                    color: "#475569",
                                  }}
                                >
                                  1 upper case letter
                                </Typography>
                              </Box>
                              <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                                {isLengthValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{ color: 'Red', marginRight: '8px' }} />}
                                <Typography
                                  style={{
                                    letterSpacing: "-0.02em",
                                    textAlign: "left",
                                    color: "#475569",
                                    fontFamily: "Spartan",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "18px",
                                  }}
                                >
                                  Between 8 and 20 characters
                                </Typography>
                              </Box>
                              <Box style={{ display: "flex", alignItems: "center", marginTop: "4px", }}>
                                {isNumberValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px', }} /> : <CancelOutlinedIcon style={{ color: 'Red', marginRight: '8px' }} />}
                                <Typography
                                  style={{
                                    lineHeight: "18px",
                                    letterSpacing: "-0.02em",
                                    fontFamily: "Spartan",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    textAlign: "left",
                                    color: "#475569",
                                  }}
                                >
                                  1 or more numbers
                                </Typography>
                              </Box>
                              <Box style={{ display: "flex", alignItems: "center", marginTop: "4px" }}>
                                {isSpecialCharValid ? <CheckCircleOutlineIcon style={{ color: '#059669', marginRight: '8px' }} /> : <CancelOutlinedIcon style={{ color: 'Red', marginRight: '8px' }} />}
                                <Typography
                                  style={{
                                    textAlign: "left",
                                    color: "#475569",
                                    fontFamily: "Spartan",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "18px",
                                    letterSpacing: "-0.02em",
                                  }}
                                >
                                  1 or more special characters
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        )}
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "520px",
                          }}>
                          <Box style={{ display: "flex", justifyContent: "center", marginTop: "30px", }}>
                            <Checkbox
                              checked={this.state.checked}
                              onChange={(e: any) => this.handleCheckboxChange(e)}
                              data-test-id="handleCheckboxChange"
                              style={{
                                color: "#003249",
                                width: "24px",
                                height: "24px",
                                marginRight: "6px",
                              }}
                            />
                            <Typography
                              style={{
                                fontFamily: "Spartan ",
                                fontSize: "12px",
                                lineHeight: "20px",
                                letterSpacing: "-2%",
                                color: "#475569",
                              }}
                            >
                              By creating an account you accept Haul-Inn's
                              <span
                                onClick={this.navigateToTermsConditions}
                                data-test-id="navigateToTermsConditions"
                                style={{
                                  cursor: "pointer",
                                  fontFamily: "Spartan",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "22px",
                                  letterSpacing: "-0.02em",
                                  textDecoration: "underline",
                                  color: "#475569",
                                  marginLeft: "6px",
                                }}>
                                terms and conditions
                              </span>
                            </Typography>
                          </Box>
                          <Button disabled={this.isDisabled()}
                            style={{
                              background: this.getBgColor(),
                              width: "100%",
                              padding: "16px",
                              color: this.getColor(this.state),
                              borderRadius: "8px",
                              fontFamily: "Spartan",
                              fontWeight: 700,
                              fontSize: "16px",
                              lineHeight: "24px",
                              letterSpacing: "-0.03em",
                              textTransform: 'none',
                              marginTop: "16px",
                            }}
                            onClick={this.createApi}
                            data-test-id="createApi">
                            Create an account
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }
        <CustomDialog style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
          open={this.state.linkSent}
          onClose={this.handleClose}
          data-test-id="handleClose">
          <Box
            style={{
              color: "#FFFFFF",
              padding: '29px 39px'
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <img src={this.state.dialogImage} />
            </Box>
            <Box
              style={{
                marginTop: "62px",
              }}>
              <Box>
                <Typography
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "30px",
                    fontWeight: "700",
                    lineHeight: "40px",
                    letterSpacing: "-0.03em",
                    textAlign: "center",
                    color: "#003249",
                  }}>
                  Check your email
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontFamily: "Spartan",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "-0.02em",
                    textAlign: "center",
                    color: "#475569",
                    width: "496px",
                  }}>
                  A confirmation email has been sent to your registered email address
                </Typography>
              </Box>
            </Box>
            <Box>
              <Button
                onClick={this.handleButtonClick}
                data-test-id="handleButtonClick"
                style={{
                  gap: "8px",
                  marginTop: "72px",
                  width: "100%",
                  padding: "16px",
                  color: "#FFFFFF",
                  backgroundColor: "#003249",
                  borderRadius: "8px",
                  fontFamily: "Spartan",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  letterSpacing: "-0.03em",
                  textTransform: 'none',
                }}>
                Resend on email
              </Button>
            </Box>
          </Box>
        </CustomDialog>
      </Wrapper>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  "& .section": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh",
    overflow: "hidden",
  },
  "& .left-section": {
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    width: "50%",
    height: "100%",
    overflow: "hidden",
    flex: "1 1 auto",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& .image": {
      height: "100vh",
      objectFit: "cover",
      width: "100%",
    },
    "& .back-btn": {
      cursor: "pointer",
      position: "absolute",
      left: "50px",
      top: "50px",
      width: "45px",
      height: "45px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "14px",
      border: "1px solid #A9ACB0E5",
      backgroundColor: "#FEFEFE26",
      boxShadow: "-5px 4px 26px 0px #0000001A",
      "& svg": {
        fontSize: "35px"
      }
    },
    "& .text-wrapper": {
      bottom: "40px",
      borderRadius: "5px",
      position: "absolute",
      background: "#FFFFFF33",
      backdropFilter: "blur(5px)",
      padding: "30px 20px",
      margin: "0px 40px",
      textAlign: "center",
      "& .text": {
        color: "#FFFFFF",
        fontFamily: "Spartan",
        fontSize: "27px",
        fontWeight: "700",
        letterSpacing: "-0.5px",
        marginBottom: "5px"
      },
      "& .sub-text": {
        color: "#FFFFFF",
        fontFamily: "Spartan",
        fontSize: "18px",
        fontWeight: "500",
        letterSpacing: "-0.5px",
        textAlign: "center",
      }
    }
  },
  "& .right": {
    width: "50%",
    height: "100%",
    overflowY: "auto",
    boxSizing: "border-box",
    maxHeight: "100vh",
    padding: "40px 20px 40px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .right-section": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#fff",
      overflowY: "auto",
      [theme.breakpoints.down("md")]: {
        overflowY: "unset"
      },
      "& .title": {
        letterSpacing: "-1px",
        fontFamily: "Spartan",
        fontWeight: "700",
        fontSize: "25px",
        color: "#003249",
      },
      "& .sub-title": {
        marginTop: "5px",
        fontFamily: "Spartan",
        fontWeight: "500",
        fontSize: "16px",
        color: "#475569",
      },
      "& .card-wrapper": {
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        "& .card": {
          cursor: "pointer",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "20px 5px",
          borderRadius: "10px",
          border: "1px solid #E2E8F0",
          "& .title": {
            letterSpacing: "-0.5px",
            fontFamily: "Spartan",
            fontWeight: "500",
            fontSize: "14px",
            color: "#475569",
            marginBottom: "8px",
            "&.selected": {
              fontWeight: "700",
              color: "##003249",
            }
          },
          "& .sub-title": {
            letterSpacing: "-0.5px",
            fontFamily: "Spartan",
            fontWeight: "500",
            fontSize: "16px",
            color: "#475569",
          },
        }
      },
    },
    "& .react-tel-input": {
      marginTop: "4px",
      "& .form-control": {
        width: "100%",
        fontSize: "13px",
        borderRadius: "8px",
        color: "#003249",
        fontFamily: "Spartan",
        fontWeight: "500",
        padding: "19px 17px 17px 62px"
      },
      "& .country-list .country": {
        padding: "10px 9px 10px 46px",
        fontSize: "14px",
        fontFamily: "Spartan",
        letterSpacing: "-0.1px",
      },
      "& .country-list .search-box": {
        padding: "5px 8px 5px",
        fontFamily: "Spartan",
      },
      "& .country-list .flag": {
        marginTop: "-5px"
      },
      "& .special-label": {
        display: "none"
      },
      "& .flag-dropdown": {
        backgroundColor: "transparent",
        borderRight: "1px solid #cacaca",
      }
    }
  }
}));

const CustomRadio = styled(Radio)({
  "& .MuiSvgIcon-root": {
    fontSize: 32,
  },
  "& .icon": {
    width: 29,
    height: 29,
    borderRadius: "50%",
    boxSizing: "border-box",
    border: "1px solid #B3BABD",
  },
  "& .checked-icon": {
    width: 13,
    height: 13,
    borderRadius: "50%",
    border: "8px solid #003249",
  }
});

const BlackButton = styled(Button)({
  width: "100%",
  marginTop: "150px",
  padding: "8px 10px",
  textTransform: "none",
  borderRadius: "8px",
  fontFamily: "Spartan",
  color: "#fff",
  fontSize: "16px",
  backgroundColor: "#003249",
  fontWeight: "700",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  }
});

const CustomTextfield = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: "8px",
    border: "1px",
    marginTop: "4px",
    color: "#003249",
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.02em",
  }
});

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "15px"
  }
});

const StyledFormLabel = styled(FormLabel)({
  fontFamily: "Spartan",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  letterSpacing: "-0.02em",
  textAlign: "left",
  color: "#64748B",
});
// Customizable Area End