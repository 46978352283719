// Customizable Area Start
import React from "react";
import {
  Box, Typography, CircularProgress, IconButton, Button,
  List, ListItemText, ListItemButton, Radio, InputBase
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Select from "react-select";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

import CreateListingController from "./CreateListingController.web";
import Header from "../../../../components/src/AppHeader.web";
import Loader from "../../../../components/src/Loader.web";
import { HomeIcon } from "../assets";
// Customizable Area End

export default class CreateListing extends CreateListingController {
  // Customizable Area Start
  renderNotFound = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className="heading">Listings</Typography>
          <IconButton>
            <AddCircleOutlineOutlinedIcon sx={{ color: "#000" }} />
          </IconButton>
        </Box>
        <Box className="not-found">
          <Box textAlign="center">
            {HomeIcon}
            <Typography className="no-listing">No listings yet</Typography>
            <Typography className="create-one">Create one now so your guest can book.</Typography>
            <BlackButton sx={{ width: "300px", marginTop: "30px" }}>Create now</BlackButton>
          </Box>
        </Box>
      </Box>
    );
  }

  renderAmenities = () => {
    const NUMBER = Array.from({ length: 20 }, (_, i) => i + 1);
    return (
      <Box className="wrapper">
        <Box width="500px">
          <Typography className="main-title">Amenities</Typography>
          <Box className="card-wrapper">
            {this.state.amenitiesList.map((item, index) => (
              <Box key={item.title} className="amenities-card">
                <Box className="center">
                  <Box display="flex" alignItems="center">
                    {item.icon && <img src={item.icon} className="icons" />}
                    <Typography className="title top">{item.type}</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box alignSelf="end">
                      <Typography className="title mb">Horses</Typography>
                      <CustomSelect
                        isSearchable={false}
                        classNamePrefix="dropdown"
                        data-test-id="horse-dropdown"
                        components={{ IndicatorSeparator: () => null }}
                        options={NUMBER.map((val) => ({ label: val, value: val }))}
                        value={{ label: item.horse_capacity, value: item.horse_capacity }}
                        onChange={(e: any) => this.handleAmenityChange(index, "horse_capacity", e.value)}
                      />
                    </Box>
                    <div className="error">{item.horseErr}</div>
                  </Box>
                </Box>
                <Box className="radio-wrapper">
                  {["free", "set_price"].map((option) => (
                    <Box
                      key={option}
                      className="radio"
                      data-test-id="radio-btn"
                      onClick={() => this.handleAmenityChange(index, "selected", option)}
                    >
                      <CustomRadio
                        checked={item.selected === option}
                        icon={<span className="icon" />}
                        checkedIcon={<span className="checked-icon" />}
                      />
                      <Typography className="option">
                        {option === "free" ? "Free" : "Set a price"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <div className="error">{item.radioErr}</div>
                {item.selected === "set_price" && (
                  <Box mt="15px">
                    <Typography className="title mb">Price / horse</Typography>
                    <CustomInputBase
                      value={item.price ?? ""}
                      data-test-id="price"
                      startAdornment={<AttachMoneyRoundedIcon sx={{ color: "#94A3B8" }} />}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!isNaN(Number(val)) || val === "") {
                          this.handleAmenityChange(index, "price", val);
                        }
                      }}
                    />
                    <div className="error">{item.priceErr}</div>
                  </Box>
                )}
              </Box>
            ))}
            <BlackButton sx={{ marginTop: "30px" }} disabled={!this.state.isAmenityValid} data-test-id="save-btn" onClick={this.updateAmenities}>Save</BlackButton>
          </Box>
        </Box>
      </Box>
    );
  }

  renderCreateListing = () => {
    return (
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className="heading">
            <ArrowBackIcon />
            <span>Create Listing</span>
          </Typography>
          <Typography className="preview">Preview</Typography>
        </Box>
        <Box display="flex">
          <StyledList>
            {this.state.option.map(({ label, status, required }, index) => (
              <ListItemButton
                key={label}
                data-test-id="option"
                onClick={() => this.onChangeOption(index)}
                className={`menu-item ${this.state.currentOption === index && "active"}`}
              >
                <ListItemText
                  primary={<Typography className="label">{label} {required && "*"}</Typography>}
                  secondary={<Typography className={`status ${status}`}>{status}</Typography>}
                />
                <KeyboardArrowRightOutlinedIcon />
              </ListItemButton>
            ))}
          </StyledList>
          {this.state.loading ? (
            <Box width="100%">
              <Box className="loading">
                <CircularProgress sx={{ color: "#000" }} />
              </Box>
            </Box>
          ) : (
            <>
              {this.state.currentOption === 3 && this.renderAmenities()}
            </>
          )}
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Header hideFilter navigation={this.props.navigation} active="Listings">
        <Wrapper>
          <Loader loading={this.state.isLoading} />
          {this.renderCreateListing()}
        </Wrapper>
      </Header>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  height: "100%",
  padding: "30px 20px",
  // paddingBottom: "105px",
  backgroundColor: "#F8FAFC",
  "& .loading": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .heading": {
    display: "flex",
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "700",
    fontSize: "18px",
    letterSpacing: "-0.5px",
    textTransform: "uppercase",
    width: "fit-content",
    cursor: "pointer",
    "& span": {
      marginLeft: "10px"
    }
  },
  "& .preview": {
    fontFamily: "Spartan",
    cursor: "pointer",
    color: "#06D6A0",
    fontWeight: "700",
    fontSize: "16px",
    letterSpacing: "-0.5px",
  },
  "& .not-found": {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    "& .no-listing": {
      marginTop: "20px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      fontSize: "16px",
      fontWeight: "700",
      color: "#64748B",
    },
    "& .create-one": {
      marginTop: "5px",
      fontFamily: "Spartan",
      letterSpacing: "-0.5px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#64748B",
    },
  },
  "& .wrapper": {
    flex: "1",
    padding: "0px 10px",
    display: "flex",
    justifyContent: "center",
    "& .main-title": {
      marginBottom: "25px",
      letterSpacing: "-0.5px",
      fontFamily: "Spartan",
      fontSize: "18px",
      fontWeight: "700",
      color: "#003249",
    },
    "& .card-wrapper": {
      display: "flex",
      gap: "25px",
      flexDirection: "column",
      "& .amenities-card": {
        padding: "20px",
        borderRadius: "16px",
        backgroundColor: "#fff",
        border: "1px solid #E2E8F0",
        "& .icons": {
          height: "31px",
          width: "auto"
        },
        "& .title": {
          letterSpacing: "-0.5px",
          fontFamily: "Spartan",
          fontSize: "14px",
          fontWeight: "700",
          color: "#003249",
          "&.top": {
            marginLeft: "10px",
            textTransform: "none",
          },
          "&.mb": {
            marginBottom: "5px"
          }
        },
        "& .radio-wrapper": {
          display: "flex",
          gap: "15px",
          marginLeft: "-5px",
          marginTop: "15px",
          "& .radio": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "2px",
            "& .option": {
              letterSpacing: "-0.5px",
              fontFamily: "Spartan",
              fontSize: "14px",
              fontWeight: "500",
              color: "#003249",
            }
          }
        }
      }
    }
  }
});

const StyledList = styled(List)({
  padding: "0px",
  marginTop: "20px",
  width: "100%",
  maxWidth: "300px",
  background: "#fff",
  height: "fit-content",
  borderRadius: "16px",
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .menu-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
    marginTop: "2px",
    marginBottom: "2px",
    paddingRight: "10px",
    borderRadius: "8px",
    "&:hover": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
    },
    "&.active": {
      borderRadius: "16px",
      backgroundColor: "#F1F5F9",
      "& .MuiListItemText-primary": {
        fontWeight: "700",
      }
    },
  },
  "& .label": {
    fontFamily: "Spartan",
    color: "#003249",
    fontWeight: "500",
    fontSize: "14px",
    letterSpacing: "-0.5px",
  },
  "& .active": {
    "& .label": {
      fontWeight: "700",
    }
  },
  "& .status": {
    fontFamily: "Spartan",
    color: "#059669",
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "-0.5px",
    "&.pending": {
      color: "#D97706"
    }
  }
});

const CustomSelect = styled(Select)({
  "& .dropdown__control": {
    borderRadius: "8px",
    width: "75px",
    minHeight: "40px",
  },
  "& .dropdown__control:hover": {
    borderColor: "#CBD5E1"
  },
  "& .dropdown__control--is-focused": {
    boxShadow: "none",
    border: "1px solid #CBD5E1",
  },
  "& .dropdown__single-value": {
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#003249",
  },
  "& .dropdown__menu-list::-webkit-scrollbar": {
    width: "4px",
    height: "4px"
  },
  "& .dropdown__option": {
    fontFamily: "Spartan",
    fontSize: "14px",
    fontWeight: "500",
    color: "#64748B",
    padding: "12px"
  },
  "& .dropdown__option--is-selected": {
    backgroundColor: "#E2E8F0",
    color: "#003249",
  },
  "& .dropdown__option--is-focused:hover": {
    color: "#003249",
    backgroundColor: "#E2E8F0"
  }
});

const CustomRadio = styled(Radio)({
  padding: "5px",
  "& .MuiSvgIcon-root": {
    fontSize: 32,
  },
  "& .icon": {
    height: 20,
    width: 20,
    borderRadius: "50%",
    boxSizing: "border-box",
    border: "1px solid #64748B",
  },
  "& .checked-icon": {
    height: 8,
    width: 8,
    borderRadius: "50%",
    border: "6px solid #003249",
  }
});

const CustomInputBase = styled(InputBase)({
  width: "100%",
  padding: "8px 8px",
  borderRadius: "6px",
  fontFamily: "Spartan",
  fontWeight: "500",
  fontSize: "14px",
  color: "#003249",
  border: "1.5px solid #E2E8F0",
  "& input": {
    height: "auto"
  }
});

const BlackButton = styled(Button)({
  padding: "8px 10px",
  width: "100%",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "14px",
  fontFamily: "Spartan",
  letterSpacing: "-0.5px",
  whiteSpace: "nowrap",
  fontWeight: "700",
  color: "#fff",
  backgroundColor: "#003249",
  border: "1.5px solid #003249",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#003249",
  },
  "&.Mui-disabled": {
    color: "#fff",
    backgroundColor: "#94A3B8",
    border: "1.5px solid #94A3B8",
  },
});
// Customizable Area End